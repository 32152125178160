import { I18n } from 'galarm-config'
import { Box, Button } from 'grommet'
import React, { useContext } from 'react'
import UserContext from './UserContext'
import { useNavigate } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth'

const GalarmAdminHeader = () => {
  const { user } = useContext(UserContext)

  const navigate = useNavigate()

  const onLogin = () => {
    navigate('/login', { replace: true })
  }

  const onLogOut = async () => {
    const auth = getAuth()
    await signOut(auth)
    navigate('/login', { replace: true })
  }

  return (
    <Box align="center" justify="end" pad="small" direction="row">
      {user ? (
        <Button
          primary
          label={I18n.t('logOut')}
          style={{ color: 'white' }}
          onClick={onLogOut}
        />
      ) : (
        <Button
          primary
          label={I18n.t('login')}
          style={{ color: 'white' }}
          onClick={onLogin}
        />
      )}
    </Box>
  )
}

export default GalarmAdminHeader
