import React from 'react'
import { Box, Card, Heading } from 'grommet'
import { SecondaryText } from 'web-components'
import { I18n } from 'galarm-config'
import { useNavigate } from 'react-router-dom'
import { getFunctions, httpsCallable } from 'firebase/functions'

// Add three cards here
// One to view organizations
// One to update products
// One to update plans
const HomePage = () => {
  const navigate = useNavigate()

  const onChooseOrgs = () => {
    navigate('orgs')
  }

  const onChooseAffiliates = () => {
    navigate('affiliates')
  }

  const updateProducts = async () => {
    console.log('Updating products')
    const functions = getFunctions()
    const syncAllProductsInternal = httpsCallable(
      functions,
      'syncAllProductsInternal'
    )

    try {
      await syncAllProductsInternal()
      console.log('Finished updating products')
    } catch (error) {
      console.error(error)
    }
  }

  const updatePlans = async () => {
    console.log('Updating plans')
    const functions = getFunctions()
    const syncAllPlansInternal = httpsCallable(
      functions,
      'syncAllPlansInternal'
    )

    try {
      await syncAllPlansInternal()
      console.log('Finished updating plans')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box gap="medium">
      <Heading level={3}>Galarm Admin</Heading>
      <Card onClick={onChooseOrgs} background="white" pad="medium" gap="small">
        <Heading level={4} margin="none">
          {I18n.t('organizations')}
        </Heading>
        <SecondaryText>View and manage organizations</SecondaryText>
      </Card>
      <Card
        onClick={onChooseAffiliates}
        background="white"
        pad="medium"
        gap="small">
        <Heading level={4} margin="none">
          {I18n.t('affiliates')}
        </Heading>
        <SecondaryText>View and manage affiliates</SecondaryText>
      </Card>
      <Card
        onClick={updateProducts}
        background="white"
        pad="medium"
        gap="small">
        <Heading level={4} margin="none">
          {I18n.t('products')}
        </Heading>
        <SecondaryText>Update products info from Stripe</SecondaryText>
      </Card>
      <Card onClick={updatePlans} background="white" pad="medium" gap="small">
        <Heading level={4} margin="none">
          {I18n.t('prices')}
        </Heading>
        <SecondaryText>Update prices info from Stripe</SecondaryText>
      </Card>
    </Box>
  )
}

export default HomePage
