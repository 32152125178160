import React, { useEffect, useState } from 'react'
import { Box, DataTable, Text, Pagination, Button } from 'grommet'
import { I18n, colors } from 'galarm-config'
import { collection, onSnapshot } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import SearchBar from './SearchBar'
import GlobalConfig from '../GlobalConfig'
import { Add } from 'grommet-icons'

const ITEMS_PER_PAGE = 10 // Number of items to display per page

const Affiliates = () => {
  const navigate = useNavigate()

  const [affiliates, setAffiliates] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    // Get enterpriseAccounts from Firestore
    const unsubscribe = onSnapshot(
      collection(GlobalConfig.firestoreDb, 'affiliates'),
      snapshot => {
        const affiliates = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        setAffiliates(affiliates)
      }
    )
    return () => {
      unsubscribe()
    }
  }, [])

  const onPageChange = ({ page, startIndex, endIndex }) => {
    console.log('page', page, 'startIndex', startIndex, 'endIndex', endIndex)
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const endIndex = startIndex + ITEMS_PER_PAGE

  const filteredAffiliates = affiliates.filter(affiliate =>
    affiliate.name?.toLowerCase().includes(searchText.toLowerCase())
  )

  const slicedData = filteredAffiliates
    .sort((a, b) => {
      if (a.creationDate && b.creationDate)
        return a.creationDate < b.creationDate ? 1 : -1
      else if (a.creationDate) return -1
      else if (b.creationDate) return 1
      else return 0
    })
    .slice(startIndex, endIndex)
    .map(affiliate => ({
      id: affiliate.id,
      name: affiliate.name,
      email: affiliate.email,
      referralCount: affiliate.referralCount || 0,
      affiliateLink:
        process.env.REACT_APP_GALARM_PRO_URL +
        '?utm_source=affiliate&affiliate_id=' +
        affiliate.id
    }))

  const columns = [
    {
      property: 'name',
      header: <Text>{I18n.t('name')}</Text>,
      render: datum => (
        <Text weight={500} onClick={() => navigate(datum.id)}>
          {datum.name}
        </Text>
      )
    },
    { property: 'email', header: <Text>{I18n.t('email')}</Text> },
    {
      property: 'referralCount',
      header: <Text>{I18n.t('referralCount')}</Text>,
      align: 'center'
    },
    {
      property: 'affiliateLink',
      header: <Text>{I18n.t('affiliateLink')}</Text>
    }
    // Add more columns as needed
  ]

  console.log('filteredAffiliates', filteredAffiliates)

  return (
    <Box align="center" pad="large" gap="small">
      <Box direction="row" gap="small">
        <SearchBar onChangeText={setSearchText} searchText={searchText} />
        <Button
          round
          style={{ background: colors.blue, borderRadius: 10 }}
          icon={<Add color="white" />}
          onClick={() => navigate('/app/affiliates/new')}
        />
      </Box>
      <DataTable
        columns={columns}
        data={slicedData}
        background={{
          header: 'light-4',
          body: ['white', 'light-2'],
          footer: { dark: 'light-2', light: 'dark-3' }
        }}
        border
      />
      <Pagination
        numberItems={filteredAffiliates.length}
        page={currentPage}
        itemsPerPage={ITEMS_PER_PAGE}
        onChange={onPageChange}
      />
    </Box>
  )
}

export default Affiliates
