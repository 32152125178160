// Avoid using 'please' in the messages
// Use active voice
// Add a period at the end of sentences

const translations = {
  // Tab Names
  alarms: 'Alarms',
  contacts: 'Contacts',
  groups: 'Groups',
  settings: 'Settings',
  notificationsTab: 'Notifications',

  // Alerts
  specifyTimeInFutureForAlarm: "Alarm can't be in the past.",
  specifyTimeInFutureForPreReminder: 'Pre-reminder for alarm is in the past.',
  specifyNameForGroup: 'Group name is required.',
  updatingGroupName: 'Updating group information.',
  unableToEditGroupName:
    'Unable to update group information. Please try again after some time.',
  selectSmallerImage: 'Image is larger than 10 MB. Select a smaller image.',
  sendingVerificationCode:
    'Sending verification code to {{formattedMobileNumber}}.',
  verifyingCode: 'Verifying the code.',
  creatingUserProfile: 'Setting up...',
  unabeToCreateUserProfile:
    'Unable to set up an account at this time. Please try again in a few moments.',
  unableToSendVerificationCode: 'Unable to send verification code. {{error}}',
  unableToVerifyCode: 'Unable to verify code. {{error}}',
  unableToRegisterUserWithoutMobileNumber: 'Unable to perform setup. {{error}}',
  permissionDeniedToAccessCameraIos: `Galarm doesn't have permission to access camera. 

Go to Settings > Galarm and allow access to camera.`,
  permissionDeniedToAccessCameraAndroid: `Galarm doesn't have permission to access camera.

Go to Settings > Apps > Galarm > Permissions and allow access to camera.`,
  permissionDeniedToAccessPhotosIos: `Galarm doesn't have permission to access photos.

Go to Settings > Galarm and allow access to photos.`,
  permissionDeniedToAccessPhotosAndroid: `Galarm doesn't have permission to access photos.

Go to Settings > Apps > Galarm > Permissions and allow access to storage.`,
  permissionDeniedToAccessCalendarIos: `Galarm doesn't have permission to access calendar.
Go to Settings > Calendars and allow access to Galarm.`,
  permissionDeniedToAccessCalendarAndroid: `Galarm doesn't have permission to access calendar.
Go to Settings > Apps > Galarm > Permissions and allow access to Calendar.`,
  verificationCodeSent: 'Verification code sent to {{formattedMobileNumber}}.',
  rateTheApp:
    'We hope you are finding Galarm useful. Please take a moment to rate us on the Play Store. Thank you for your support.',
  backgroundAppRefreshDisabledPermission: `Alarms may not ring because Galarm doesn't have permission to perform background app refresh.

Tap "Enable", and enable "Background App Refresh".`,
  selectedGroupUnselected:
    'You can either select a group or a set of contacts as participants. Your previously selected group will be unselected. Proceed?',
  selectedContactsUnselected:
    'You can either select a group or a set of contacts as participants. Your previously selected contacts will be unselected. Proceed?',
  notificationsDisabledIos: `Alarms will not ring because Galarm doesn't have permission to show notifications.

Go to Settings > Galarm > Notifications and enable "Alerts", "Sounds", and "Banners".`,
  notificationsDisabledAndroid: `Alarm notifications will not appear on the screen.

Tap "Enable", select "Notifications" and allow notifications.`,
  alarmNotificationChannelDisabled: `Alarm notifications will not pop up on the screen. 

Tap "Enable", and allow the alarm notifications to pop up on the screen.`,
  alarmLockScreenNotificationlDisabled: `Alarm notifications will not show on the lock screen. 

Tap "Enable", and allow the alarm notifications to show on the lock screen.`,
  alarmWillNotRingDueToNotificationsDisabledIos: `Alarms will not ring because notifications are disabled.

Go to Settings > Galarm > Notifications and enable "Alerts", "Sounds", and "Banners".`,
  alarmWillNotRingDueToCriticalAlertsDisabledIos: `Alarms will not ring when phone is muted because critical alerts are disabled.

Go to Settings > Galarm > Notifications and enable "Critical Alerts".`,
  alarmWillNotRingDueToNotificationsDisabledAndroid: `The alarm will not appear on the screen because notifications are disabled.

Tap "Enable", select "Notifications" and allow notifications.`,
  noNotificationsScheduledAlarmInPast:
    'Alarm is in the past. No notifications are scheduled.',
  alarmOverAllowedLimit: {
    zero: `
You have reached your alarm limit of {{limit}} alarms.

Please delete or reuse an existing alarm.`,
    one: `You have reached your alarm limit of {{limit}} alarms.

You have 1 inactive alarm that you can delete or reuse.`,
    other: `You have reached your alarm limit of {{limit}} alarms.

You have {{count}} inactive alarms that you can delete or reuse.`
  },
  alarmAlreadyConfirmed: "You've already confirmed.",
  alarmAlreadyDeclined: "You've already declined.",
  participantMovedToPosition: '{{name}} moved to position {{count}}.',
  memberIsFirstParticpant: '{{name}} is the first participant.',
  memberIsLastParticpant: '{{name}} is the last participant.',
  cantContinueWithRegistrationWhenOffline:
    "Can't continue with registration because device is offline.",
  cantContinueWithRegistrationWhenOfflineWithTryAgain:
    "Can't continue with registration because device is offline. Make sure that you are connected to the internet.",
  confirmBlockContact:
    'You will no longer receive any alarms from {{name}}. Proceed?',
  confirmUnblockContact:
    'Unblocking {{name}} will also restore blocked alarms from them. Proceed?',
  unblockingContact: 'Unblocking {{name}} and restoring alarms.',
  unableToUnblockContact: 'Unable to unblock the contact. {{error}}',
  blockingContact: 'Blocking {{name}}.',
  unableToBlockContact: 'Unable to block contact. {{error}}',
  cantAddContactToAlarmBecauseBlocked:
    'You blocked {{name}}. Unblock them to add as a participant of the alarm.',
  cantAddContactToGroupBecauseBlocked:
    'You blocked {{name}}. Unblock them to add as a member of the group.',
  sendMessageNotAllowedForPastAlarms:
    "Messages can't be sent for inactive alarms.",
  nameIsRequired: 'Name is required.',
  confirmDisableGroupAlarm:
    'Disabling the group alarm will also disable the alarm for other participants. Proceed?',
  removeMemberFromGroup: 'Do you want to remove {{name}} from this group?',
  leaveGroupConfirm: 'Do you want to leave this group?',
  deviceOffline:
    'Device is offline. Make sure that you are connected to the internet and try again.',
  cantUpdateTimezone: "Can't Update Time Zone",
  cantUpdateGroupDefaulConfigWhenOffline:
    'The modified group configuration will not be applied to future alarms because the device is offline.',
  specifyFeedback: 'Feedback is required.',
  specifyEmailId: 'Email address is required.',
  specifyValidEmailId: 'Email address is not valid.',
  specifyProblem: 'Problem description is required.',
  appInstalledUsingSameNumberOnDifferentPhone:
    'You have installed Galarm on a different device using the same number. The app on this device is reset.',
  invalidUid:
    "This user account doesn't exist anymore. The Galarm app on this device is reset.",
  accountDeleted: 'Your account has been deleted.',
  deleteAccount: 'Delete My Account',
  deletingAccount: 'Deleting your account',
  howCanWeImprove: 'We are sorry to see you go. How can we improve?',
  noMusicAlert: 'No music files found.',
  noLongerAvailableMusic:
    '{{fileName}} is no longer available. Ringtone has been changed to {{defaultRingtoneName}}',
  userInactiveTitle: 'Welcome back!',
  userInactiveMessage:
    'Create alarms for important things and never miss them.',
  createAlarm: 'Create Alarm',
  updatedAppAvailable: 'A new version of the app is available.',
  alarmOccurrenceDisabled: 'Disabled for this occurrence',
  ownAlarmOccurrenceDone: {
    zero: 'Marked done at {{timeString}}',
    one: 'Marked done at {{timeString}} on {{dateString}}'
  },
  ownAlarmOccurrenceSkipped: {
    zero: 'Skipped at {{timeString}}',
    one: 'Skipped at {{timeString}} on {{dateString}}'
  },
  enabledAlarmsCount: {
    one: '1 alarm enabled.',
    other: '{{count}} alarms enabled.'
  },
  enabledAndNotEnabledAlarmsCount: {
    one: '1 alarm enabled. Alarms without future occurences remain disabled.',
    other:
      '{{count}} alarms enabled. Alarms without future occurences remain disabled.'
  },
  disabledAlarmsCount: {
    one: '1 alarm disabled.',
    other: '{{count}} alarms disabled.'
  },
  ownAlarmOccurrenceUnacknowledged: 'Not responded',
  participantAlarmOccurrenceDone: {
    zero: 'Marked done by {{participantName}} at {{timeString}}',
    one: 'Marked done by {{participantName}} at {{timeString}} on {{dateString}}'
  },
  participantAlarmOccurrenceSkipped: {
    zero: 'Skipped by {{participantName}} at {{timeString}}',
    one: 'Skipped by {{participantName}} at {{timeString}} on {{dateString}}'
  },
  participantAlarmOccurrenceUnacknowledged:
    "{{participantName}} didn't respond",
  groupAlarmOccurrenceConfirmed: {
    zero: 'Confirmed at {{timeString}}',
    one: 'Confirmed at {{timeString}} on {{dateString}}'
  },
  groupAlarmOccurrenceDeclined: {
    zero: 'Declined at {{timeString}}',
    one: 'Declined at {{timeString}} on {{dateString}}'
  },

  // Alert Titles
  warning: 'Warning',
  info: 'Info',
  confirm: 'Confirm',
  error: 'Error',
  cantUploadImage: "Can't Upload Image",
  cantDeleteImage: "Can't Delete Image",
  cantEditGroup: "Can't Edit Group",
  cantAddNewContact: "Can't Add New Contact",
  cantDeleteContact: "Can't Delete Contact",
  cantEditContact: "Can't Edit Contact",
  cantSaveAlarm: "Can't Save Alarm",
  cantSaveGroup: "Can't Save Group",
  cantReloadContacts: "Can't Reload Contacts",
  cantTakePhoto: "Can't Take Photo",
  cantExportToCalendar: "Can't Export to Calendar",
  cantOpenPhotoLibrary: "Can't Open Photo Library",
  cantDeleteAlarmHistory: "Can't Delete Alarm History",
  cannotDuplicateAlarm: "Can't Duplicate Alarm",
  upgradeToPremiumToDuplicateAlarm:
    'Upgrade to Premium to create more group and buddy alarms.',
  confirmAcceptAlarm: 'Accept Alarm?',
  confirmRejectAlarm: 'Decline Alarm?',
  deleteAlarm: 'Delete Alarm?',
  restoreAlarmConfirmation: 'Restore Alarm?',
  respondToRepeatingAlarm: {
    zero: 'You are added as a recipient to alarm {{alarmName}} by {{alarmCreatorName}}. You will be alerted at {{alarmTimeString}} {{alarmRepetitionString}} starting {{alarmDateString}}',
    one: 'You are added as a participant to alarm {{alarmName}} by {{alarmCreatorName}}. You will be alerted at {{alarmTimeString}} {{alarmRepetitionString}} starting {{alarmDateString}}'
  },
  respondToOneTimeAlarm: {
    zero: 'You are added as a recipient to alarm {{alarmName}} by {{alarmCreatorName}}. You will be alerted at {{alarmTimeString}} {{alarmDateString}}',
    one: 'You are added as a participant to alarm {{alarmName}} by {{alarmCreatorName}}. You will be alerted at {{alarmTimeString}} {{alarmDateString}}'
  },
  cantMoveMemberUp: "Can't Move {{name}} Up",
  cantMoveMemberDown: "Can't Move {{name}} Down",
  decline: 'Decline',
  cantEditProfile: "Can't Edit Profile",
  cantSelectContact: "Can't Select Contact",
  cantSendMessage: "Can't Send Message",
  cantSendVerificationCode: "Can't Send Verification Code",
  confirmBlockContactTitle: 'Confirm Block Contact',
  confirmUnblockContactTitle: 'Confirm Unblock Contact',
  cantSaveProfile: "Can't Save Profile",
  cantBlockContact: "Can't Block Contact",
  cantUnblockContact: "Can't Unblock Contact",
  cantLoadEarlierMessages: "Can't Load Earlier Messages",
  cantRestoreAlarm: "Can't Restore Alarm",
  cantSendFeedback: "Can't Submit Feedback",
  cantSubmitProblem: "Can't Submit Problem",
  cantDeletePastAlarmsInBulk: "Can't Delete Inactive Alarms in Bulk",
  cantAddNewGroup: "Can't Add New Group",
  alreadySnoozing: 'Already Snoozing',
  markAlarmUndone: 'You marked this alarm ‘Done’ {{durationString}} ago. Undo?',
  markAlarmUnskipped: 'You skipped this alarm {{durationString}} ago. Undo?',
  cantUndo: "Can't Undo",
  fetchMusicFromPhone: 'Finding available music.',
  enableAllAlarmsInCategory: 'Enable all alarms in this category?',
  disableAllAlarmsInCategory: 'Disable all alarms in this category?',
  allAlarmsAlreadyEnabled: 'All alarms in this category are already enabled.',
  allAlarmsAlreadyDisabled: 'All alarms in this category are already disabled.',

  // Labels
  time: 'Time',
  timeForBuddy: 'Time for Buddy',
  timeForYou: 'Remind me at',
  name: 'Name',
  title: 'Title',
  notes: 'Notes',
  repeat: 'Repeat',
  preReminder: 'Pre-Reminder',
  remove: 'Remove',
  preReminderShortAlert: '{{duration}} Pre-Reminder',
  preReminderTitle: '{{alarmName}} at {{date}}',
  preReminderGroupHelperText: `This pre-reminder is only for you. Other alarm participants can set their own pre-reminders once they receive the alarm.`,
  addPreReminder: 'Add Pre-Reminder',
  helperTextForPreReminder:
    'Tap the reset button on top to remove the pre-reminder.',
  edit: 'Edit',
  delete: 'Delete',
  creator: 'Creator',
  useAsDefaultGroupConfiguration: 'Apply to Future Alarms',
  done: 'Done',
  skipped: 'Skipped',
  save: 'Save',
  members: 'Members',
  mobileNumber: 'Phone Number',
  enter: 'Enter',
  addMembers: 'Add Members',
  respond: 'Respond',
  yes: 'Yes',
  no: 'No',
  cancel: 'Cancel',
  participants: 'Participants',
  takePhoto: 'Take Photo',
  chooseFromLibrary: 'Choose From Library',
  resendCode: 'Resend Code',
  register: 'Register',
  inviteFriends: 'Share This App',
  invite: 'Invite',
  incorrectNumber: 'Incorrect Number?',
  date: 'Date',
  cascadingAlarmInterval: 'Time between participant alerts',
  ringParticipantAlarmsByDefault: 'Ring alarms without accepting',
  alarmRingtone: 'Alarm Ringtone',
  pickASong: 'Pick music from phone',
  privacyPolicy: 'Privacy Policy',
  sendFeedback: 'Feedback',
  galarmWeb: 'Galarm Web',
  galarmEnterprise: 'Galarm Pro',
  galarmAdmin: 'Galarm Admin',
  troubleshooting: 'Troubleshooting',
  alarmDidntRingHelp: "Help! My alarm didn't ring",
  autoStartSettingHelperText:
    'If your phone has auto-start setting, please make sure that auto-start is enabled for Galarm.',
  batteryOptimizationHelperText: 'Disable battery optimization for Galarm',
  troubleshootingHelperText: `The following settings may be preventing alarms from ringing as expected. Please review these settings.`,
  getMoreHelpText: 'Still having problems?',
  autoStartSetting: 'Auto-start',
  batteryOptimization: 'Battery optimization',
  battery: 'Battery',
  rateTheAppSettings: 'Rate This App',
  about: 'About & Help',
  leaveGroup: 'Leave Group',
  removeGroup: 'Remove Group',
  viewMember: 'View {{memberName}}',
  removeMember: 'Remove {{memberName}}',
  changeMemberState: 'Change who can alert {{memberName}}',
  changeYourGroupState: 'Change who can alert you',
  numMembersInGroup: 'Members: {{count}}',
  notifications: 'App Notifications are disabled',
  backgroundAppRefresh: 'Background App Refresh is disabled',
  groupsInCommon: 'Groups in Common',
  alarmsInCommon: 'Alarms in Common',
  close: 'Close',
  responsePending: 'Your Response?',
  later: 'Later',
  install: 'Install',
  all: 'All',
  numYears: {
    one: '1 year',
    other: '{{count}} years'
  },
  numMonths: {
    one: '1 month',
    other: '{{count}} months'
  },
  numDays: {
    one: '1 day',
    other: '{{count}} days'
  },
  hours: {
    one: '1 hour',
    other: '{{count}} hours'
  },
  minutes: {
    one: '1 minute',
    other: '{{count}} minutes'
  },
  seconds: {
    one: '1 second',
    other: '{{count}} seconds'
  },
  minutesSmall: '{{count}} min',
  secondsSmall: '{{count}} sec',
  hoursAfter: {
    one: '1 hour after',
    other: '{{count}} hours after'
  },
  minutesAfter: {
    one: '1 minute after',
    other: '{{count}} minutes after'
  },
  hoursAfterBuddy: {
    one: '1 hour after buddy',
    other: '{{count}} hours after buddy'
  },
  minutesAfterBuddy: {
    one: '1 minute after buddy',
    other: '{{count}} minutes after buddy'
  },
  longerThanAnHour: '> 1 hour',
  licenses: 'Third-Party Licensing',
  version: 'Version',
  termsOfService: 'Terms of Service',
  showPrivacyPolicy: '> Privacy Policy',
  showTermsOfService: '> Terms of Service',
  showLicenses: '> Third-Party Licensing',
  change: 'Change',
  snooze: 'Snooze',
  ringOnVibrate: 'Ring on Silent',
  vibrate: 'Vibrate',
  ringOnEarphoneOnly: 'Ring alarm on earphones only',
  ringOnSpeakerAndEarphone: 'Ring alarm on speaker and earphones',
  whenEarphoneIsConnected: 'When earphones are connected',
  tapGesture: 'Tap',
  slideGesture: 'Slide',
  silent: 'Silent',
  volume: 'Volume',
  soundAndVibration: 'Sound and Vibration',
  advancedSettings: 'Advanced Settings',
  gestureToDismissAnAlarm: 'Gesture to dismiss an alarm',
  someNotificationsAreMuted: 'Some notifications are muted',
  allNotificationsEnabled: 'All notifications are enabled',
  notificationSettings: 'Notification Settings',
  galarmWebNotifications: 'Galarm Web Notifications',
  alarmsfromOthersNotifications: 'Participant Alarms Notifications',
  sharedAlarmNotifications: 'Shared Alarm Notifications',
  alarmChatNotifictions: 'Alarm Chat Notifications',
  groupNotifications: 'Group Notifications',
  alarmAcknowledgementNotifications: 'Alarm Acknowledgement Notifications',
  send: 'Send',
  prev: 'Prev',
  snoozing: '{{timeString}}',
  specifySnoozeDuartion: 'Specify snooze duration',
  chooseDifferentDuration: 'Choose a different duration',
  noCalendarsFound: 'No calendars found on the device.',
  eventWillEndAt: 'Event will end at {{timeString}}.',
  selectDurationWithinTheSameDay: `Event can't cross the day boundary.`,
  cannotMapRepeatTypeInTheDeviceCalendar: `Unable to "Export to Calendar" because your alarm's "Repeat" setting is not supported by the calendar.`,
  alarmSavedToCalendar: 'Alarm has been successfuly saved to calendar.',
  faq: 'Frequently Asked Questions',
  openSettings: 'Open Settings',
  openAppSettings: 'Open App Settings',
  alreadyDone: 'Done',
  enableAutoStart: 'Enable Autostart',
  restrictedApps: 'Restricted Apps',
  unmonitoredApps: 'Unmonitored Apps',
  enableProtected: 'Protected Apps',
  manageAppLaunch: 'Manage App Launch',
  unblockContact: 'Unblock Contact',
  blockContact: 'Block Contact',
  call: 'Call {{name}}',
  text: 'Text {{name}}',
  chat: 'Chat',
  ok: 'OK',
  stopSnooze: 'Stop Snooze',
  emailId: 'Your Email Address',
  feedback: 'Feedback',
  submit: 'Submit',
  reportAProblem: 'Report a Problem',
  enableAlarm: 'Enable Alarm',
  restoreAlarm: 'Restore Alarm',
  deletePastAlarms: 'Delete Inactive Alarms',
  accept: 'Accept',
  defaultPersonalAlarmName: 'Personal Reminder',
  defaultGroupAlarmName: 'Group Event',
  defaultRecipientAlarmName: 'Buddy Alarm',
  search: 'Search',
  recent: 'Recent',
  upcoming: 'Upcoming',
  missed: 'Missed',
  expired: 'Expired',
  past: 'Past',
  active: 'Active',
  inactive: 'Inactive',
  allAlarms: 'All Alarms',
  myAlarms: 'My Alarms',
  participantAlarms: 'Participant Alarms',
  pendingParticipantAlarms: 'New Alarms from Others',
  alarmsWithUnreadChatMessages: 'Alarms with New Chat',
  systemNotifications: 'System Messages',
  missedAlarms: 'Missed Alarms',
  recentlyDeletedAlarms: 'Recently Deleted Alarms',
  searchAlarms: 'Search Alarms',
  participant: 'Participant',
  you: 'You',
  none: 'None',
  onceOnly: 'Once only',
  weekdays: 'Weekdays',
  weekends: 'Weekends',
  everyDayString: 'Daily',
  everySunday: 'Every Sunday',
  everyMonday: 'Every Monday',
  everyTuesday: 'Every Tuesday',
  everyWednesday: 'Every Wednesday',
  everyThursday: 'Every Thursday',
  everyFriday: 'Every Friday',
  everySaturday: 'Every Saturday',
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  shortMay: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',
  sun: 'Sun',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  first: '1st',
  second: '2nd',
  third: '3rd',
  fourth: '4th',
  fifth: '5th',
  every: 'Every',
  oddNumberedDays: 'Odd Numbered Dates',
  evenNumberedDays: 'Even Numbered Dates',
  noParticipants: 'No Participants',
  typeYourMessage: 'Type your message',
  cantPostMessageToPastAlarm: "Can't post message to an inactive alarm",
  groupName: 'Group: {{name}}',
  alarm: 'Alarm',
  confirmed: 'confirmed',
  declined: 'declined',
  Done: 'Done',
  Skip: 'Skip',
  Confirm: 'Confirm',
  Decline: 'Decline',
  Accept: 'Accept',
  today: 'today',
  on: 'on',
  recipientAlarm: 'Buddy Alarm',
  recipient: 'Recipient',
  remindMeAfter: 'Remind me',
  remindMeIn: 'Remind me in',
  newRecipientAlarm: 'New Buddy Alarm',
  editRecipientAlarm: 'Edit Buddy Alarm',
  newInstantAlarm: 'New Instant Alarm',
  instantAlarms: 'Instant Alarms',
  alarmDone: 'Alarm Marked Done',
  alarmSkipped: 'Alarm Skipped',
  undo: 'Undo',
  enterVerificationCode: 'Please enter the 4-digit code below:',
  initializing: 'Initializing',
  tryAgain: 'Try Again',
  nMore: '+ {{count}} more',
  enableNotification: 'Enable',
  enableAlarmNotificationChannel: 'Enable',
  enableBackgroundAppRefresh: 'Enable',
  viewImpactedAlarms: 'Alarms',
  viewImpactedGroups: 'Groups',
  dismiss: 'Dismiss',
  minsInterval: '+ {{count}}m',
  hoursInterval: '+ {{count}}h',
  daysInterval: '+ {{count}}d',
  snoozeUnitHours: 'Hours',
  snoozeUnitMinutes: 'Minutes',
  snoozeUnitDays: 'Days',
  selectMinutes: 'Select minutes',
  selectHours: 'Select hours',
  selectDays: 'Select days',
  selectHoursAndMinutes: 'Select hours and minutes',
  enterDuration: 'Duration',
  snoozeUnit: 'Unit',
  specifySnoozeUnit: 'Specify snooze unit',
  specifySnoozeDuration: 'Specify snooze duration',
  duration: 'Duration',
  quickAlarmName: {
    zero: '{{minutes}} min reminder',
    one: '{{hours}} hour reminder',
    other: '{{hours}} hour {{minutes}} min reminder'
  },
  alarmTitleForGoogleVoiceTimerCommand: '{{durationString}} reminder',
  addedAlarmFromVoiceCommand:
    'Added an alarm for {{alarmName}} at {{dateString}}',
  addedTimerFromVoiceCommand: 'Added a timer for {{duration}}',
  clear: 'Clear',
  ringtoneDuration: 'Ringtone Duration',
  chooseDuration: 'Tap a duration. You can tap multiple times.',
  newAlarm: 'New Alarm',
  quickAlarm: 'Quick Reminder',
  quickAlarmWillRingAt: 'The alarm will ring at {{timeString}}.',
  preReminderWillRingAt: {
    zero: `A pre-reminder for this alarm will ring 
at {{timeString}}.`,
    one: `A pre-reminder for this alarm will ring 
at {{timeString}} the previous day.`,
    other: `A pre-reminder for this alarm will ring 
at {{timeString}} {{count}} days before.`
  },
  random: 'Random',
  enableAllAlarms: 'Enable all alarms',
  disableAllAlarms: 'Disable all alarms',
  deleteAllAlarms: 'Delete all alarms',
  hideCategory: 'Hide Category',
  unhideCategory: 'Unhide Category',
  dontShowHiddenCategories: "Don't show hidden categories",
  showHiddenCategories: 'Show hidden categories',
  welcomeAlarmsTitle: `Let's get started!`,
  welcomeAlarmsMessage: 'Here are some commonly used alarms:',
  onboardingAlarms: 'Onboarding Alarms',
  hiddenAlarmCategories: 'Hidden categories',
  restorePurchases: 'Restore Purchases',
  noAvailablePurchasesFound: `No available purchases found for your account.

Please send us a feedback using "Settings > Feedback" if you have a valid purchase that you are not able to restore.`,
  restoringPurchases: 'Restoring purchases',

  // Screen messages
  fetchingContacts: 'Checking which contacts are using Galarm',
  enterNameAndUploadPhotoForUser:
    'Enter your name below and optionally upload your profile photo',
  enterNameAndUploadPhotoForGroup:
    'Enter a name below and optionally upload a group photo',
  noContactsWithAppInstalled: 'No one in your contacts is using Galarm.',
  galarmContacts: {
    one: '1 Galarm contact',
    other: '{{count}} Galarm contacts'
  },
  contactsPendingInvitation: {
    one: '1 pending invitation',
    other: '{{count}} pending invitations'
  },
  otherContacts: {
    one: '1 other contact',
    other: '{{count}} other contacts'
  },
  over100Contacts: 'Over 100 other contacts',
  noAlarms: `You don't have any alarms. Tap + to add a new alarm.`,
  noContactsWeb: {
    one: `We didn't find any contacts using Galarm. Either we don't have permission to access your contacts or no one in your contacts is using Galarm. You can sync your contacts from the mobile app.

You can add contacts using Galarm to your alarms in different ways to benefit from the app`,
    other: `Please register on the mobile app to start adding contacts to your alarms.`
  },
  viewingAlarmsInCommon: 'Alarms in common with {{name}}',
  viewingGroupsInCommon: 'Groups in common with {{contactName}}',
  changeGroupMemberConfigTextForCascadingAlarm:
    'Active participants will be alerted in the displayed order.',
  changeGroupMemberConfigTextForSimultaneousAlarm:
    'Only active participants will be alerted.',
  changeGroupMemberStateText: {
    zero: `Change members who can alert you when an alarm is created for the "{{groupName}}" group.

You will be alerted only if the alarm is created by allowed members.`,
    one: `Change members who can alert {{memberName}} when an alarm is created for the "{{groupName}}" group.

{{memberName}} will be alerted only if the alarm is created by allowed members.`
  },
  allowed: 'Allowed',
  notAllowed: 'Not Allowed',
  changeStatus: 'Allowed Members',
  noUpcomingAlarms: 'No upcoming alarms',
  showMore: 'Show More',
  nMorePastAlarms: {
    one: '1 more inactive alarm',
    other: '{{count}} more inactive alarms'
  },
  shareAppMessage:
    'I get all my things done in a timely manner by creating reminders for them using the Galarm app. Through this app, we can also include each other in our reminders. I recommend downloading this innovative alarm and reminder app. {{downloadLink}}',
  addingMembers: 'Adding members.',
  unableToAddMembers: 'Unable to add members. {{error}}',
  removingMember: 'Removing member.',
  unableToRemoveMember: 'Unable to remove member. {{error}}',
  leavingGroup: 'Leaving group.',
  unableToLeaveGroup: 'Unable to leave group. {{error}}',
  expiredPersonalAlarmForCreator: {
    zero: 'This alarm expired {{durationString}} ago. Tap "Done" to complete the alarm.',
    one: 'This alarm expired {{durationString}} ago. Tap "Done" to complete the alarm and notify participants.',
    other:
      'This alarm expired {{durationString}} ago. Tap "Done" to complete the alarm and notify participants.'
  },
  donePersonalAlarmForCreator:
    'This alarm expired {{durationString}} ago. The alarm is marked as done.',
  skippedPersonalAlarmForCreator:
    'This alarm expired {{durationString}} ago. You have skipped the alarm.',
  expiredGroupAlarm:
    'This alarm expired {{durationString}} ago. Please confirm your participation.',
  expiredPersonalAlarmForParticipant:
    'This alarm expired {{durationString}} ago. Please remind {{creatorName}}.',
  alarmDurationAndroid: 'Alarm set for {{durationString}} from now.',
  alarmDurationIos: 'Alarm set for {{durationString}} from now.',
  alarmFeedbackHeader:
    'You will be sending part of this alarm’s information for debugging purposes. Any personal data such as alarm name, notes, or participants will not be shared.',
  privacyPolicyHeader: `Respect for your privacy constantly ticks in our clocks. Just like you, we value privacy, so we want you to know that we do not share your personal information with anyone, unless needed for installing the application or when required by law. In all cases, we never sell your information. It’s as simple as that!
 
The alarms you create are viewable only on your device and, if an alarm includes other participants, on the devices of those participants.
 
We access your phone’s address book/contact list only to populate the contacts tab in Galarm. We do not store that list for any other purpose. We honor that it’s your address book!
 
We access your camera and photos only to enable you to set a good profile picture. This picture shows up as your avatar or as profile picture of a group you edit. Other Galarm users who are members of alarms you set will be able to see this picture.

We send your device's public IP and its unique ID with all requests, e.g., while requesting and verifying the code during registration process. This information is not linked to your account and is only used to determine any potential malicious activity.
 
Please review the complete privacy policy for more details on the information we collect and how we use it.`,
  termsOfServiceHeader: `By using the Galarm app, you are agreeing to our Terms of Service, which govern your access to and use of this mobile app and the features and services it offers. 

Galarm’s motto is simple -- help you in never missing any event!  Whether it is setting up Personal Alarms for wake up alerts, being on time for appointments, and setting reminders for activities such as taking medication timely, or creating Group Alarms for organizing group activities like movie outings, sports events, and parties, Galarm enables you to have a social alarm clock to remind each other and track each other’s status and communicate.

Please only use Galarm if you agree to these terms. Below is a link to it.`,
  acknowledgedPersonalAlarm:
    'You marked the alarm "Done" {{durationString}} ago.',
  skippedPersonalAlarm: 'You skipped the alarm {{durationString}} ago.',
  authenticatingWithServer: 'Connecting...',
  selectParticipantsForPersonalAlarm:
    'Select a group or a set of people who act as backups for your personal alarm.',
  selectParticipantsForGroupAlarm:
    'Select a group or a set of people for your group activity.',
  acknowledgedGroupAlarm:
    'You {{acknowledgedAsString}} this alarm {{durationString}} ago.',
  acknowledgedExpiredGroupAlarm:
    'This alarm expired {{durationString}} ago. You {{acknowledgedAsString}} this alarm.',
  acknowledgedPersonalAlarmForParticipant:
    '{{creatorName}} marked the alarm "Done" {{durationString}} ago.',
  acknowledgedExpiredPersonalAlarmForParticipant:
    'This alarm expired {{durationString}} ago. {{creatorName}} marked the alarm "Done".',
  entityNotFound:
    'Oops! We were unable to find the selected {{entityType}}. We may still be retrieving it or it may have been removed.',
  goBack: 'Go Back',
  uploadingImageToServer: 'Uploading image to server.',
  deletingImageFromServer: 'Deleting image from server',
  errorUploadingImageToServer:
    'Error uploading image to server. Please try again later.',
  unableToRemoveGroup: 'Unable to remove group. {{error}}',
  addingGroup: 'Adding group.',
  editingGroup: 'Editing group.',
  unableToAddGroup: 'Unable to add group. {{error}}',
  unableToEditGroup: 'Unable to edit group. {{error}}',
  thanksForFeedback: 'Thank you for helping us improve.',
  licensesHeader:
    'Galarm includes software licensed by third-parties. Please see our Third-Party Licensing page for corresponding attribution and licensing information.',
  alarmAlreadyInSnooze: 'You will be reminded in {{durationString}}.',
  carrierChargesMayApply: 'Carrier charges may apply.',
  verficationCodeInfo:
    'You will receive a text message or a phone call with a 4-digit code.',
  selectCascadingInterval:
    "The first participant will be alerted {{firstParticipantDurationString}} after the alarm time, the second participants will be alerted {{secondParticipantDurationString}} after the alarm time and so on if you don't mark the alarm as done.",
  reorderParticipantsScreenDescription:
    'Participants will be alerted in the displayed order.',
  noMembers: 'No members',
  selectedGroupDescription: {
    zero: '{{numActiveMembers}} participants',
    one: '{{numActiveMembers}} active and {{numInactiveMembers}} inactive participant',
    other:
      '{{numActiveMembers}} active and {{numInactiveMembers}} inactive participants'
  },
  groupAlarmWillRingAtSameTime:
    'All participants will be alerted at {{timeString}}.',
  welcomeToGalarm: 'Welcome to Galarm',
  checkingConnection: 'Checking connection',
  deletePastAlarmsTitle: 'Delete inactive alarms older than',
  deletePastAlarmsOption: {
    zero: '{{option}} (0 alarms)',
    one: '{{option}} (1 alarm)',
    other: '{{option}} ({{count}} alarms)'
  },
  deletePastAlarmsNotificationMessage: 'Delete inactive alarms to reuse them.',
  chooseSnoozeInterval: 'Remind me again in',
  personalAlarmDescription:
    'Use a personal alarm for waking up, taking medication, or any other task for yourself. You can optionally add participants who are alerted if you miss an alarm so that they can remind you. The participants are also notified when you mark the alarm done.',
  groupAlarmDescription:
    'Use a group alarm for group activities such as outings, parties, or any other event. Alarm goes off at the same time for all the participants and they can confirm their participation.',
  recipientAlarmDescription: `Create alarms for someone else (“buddy”) to remind them of things they need to do. You are alerted to remind the buddy in case they miss the alarm. You will also be notified once the buddy marks the alarm done.
  
Note that the alarms are created in your time zone.`,
  whatsThis: "What's this?",
  alarmCurrentlyDisabled: 'This alarm is currently disabled',
  alarmCurrentlyRejected: 'This alarm is currently rejected',
  currentlyTypingUsers: {
    one: '{{currentlyTypingUsers}} is typing...',
    other: '{{currentlyTypingUsers}} are typing...'
  },
  noSearchResultsFound: 'No {{type}} found',
  noGroupsCreated: "You haven't created any groups.",
  noGroupsWeb: {
    one: "You haven't created any groups.",
    other:
      'Please register on the mobile app to start creating groups and adding them to alarms.'
  },
  internalErrorDuringRegistration:
    'An internal error occurred during user registration. Please try again after a few minutes',
  selectRecipientAlarmInterval: {
    zero: 'You will be alerted at the same time as the recipient',
    one: "You will be alerted {{durationString}} after the alarm time if the recipient doesn't mark the alarm 'Done'",
    other:
      "You will be alerted {{durationString}} after the alarm time if the recipient doesn't mark the alarm 'Done'"
  },
  selectRecipient: 'Select Recipient',
  acknowledgedExpiredRecipientAlarm:
    'This alarm expired {{durationString}} ago. You marked this alarm "Done".',
  skippedExpiredRecipientAlarm:
    'This alarm expired {{durationString}} ago. You skipped this alarm.',
  expiredRecipientAlarm:
    'This alarm expired {{durationString}} ago. Tap "Done" to complete the alarm and notify {{creatorName}}.',
  acknowledgedRecipientAlarm:
    'You marked this alarm "Done" {{durationString}} ago.',
  skippedRecipientAlarm: 'You skipped this alarm {{durationString}} ago.',
  acknowledgedExpiredRecipientAlarmForCreator:
    'This alarm expired {{durationString}} ago. {{recipientName}} marked the alarm "Done".',
  skippedExpiredRecipientAlarmForCreator:
    'This alarm expired {{durationString}} ago. {{recipientName}} skipped the alarm.',
  expiredRecipientAlarmForCreator:
    'This alarm expired {{durationString}} ago. Please remind {{recipientName}}.',
  acknowledgedRecipientAlarmForCreator:
    '{{recipientName}} marked the alarm "Done" {{durationString}} ago.',
  selectRecipientForRecipientAlarm: 'Select a recipient for the alarm',
  selectAtLeastOneParticipantForGroupAlarm:
    'Add at least one participant to the alarm',
  addAtLeastOneMemberToGroup: 'Add at least one member to the group',
  atTheSameTime: 'At the same time',
  myself: 'Myself',
  group: 'Group',
  someoneElse: 'Someone Else',
  participantsAreNotified: 'Participants are notified.',
  creatorIsNotified: '{{creatorName}} is notified.',
  membersAddedToGroup: {
    one: '{{memberNames}} is added to group {{groupName}}.',
    other: '{{memberNames}} are added to group {{groupName}}.'
  },
  memberRemovedFromGroup:
    '{{memberName}} is removed from group {{groupName}} which may have impacted some of your alarms.',
  youRemovedFromGroup:
    'You are removed from group {{groupName}} which may have impacted some of your alarms.',
  memberLeftGroup:
    '{{memberName}} left group {{groupName}} which may have impacted some of your alarms.',
  youLeftGroup:
    'You left group {{groupName}} which may have impacted some of your alarms.',
  groupLeft: 'You have left group {{groupName}}.',
  memberMovedToADifferentTimezone:
    '{{memberName}} moved to a different time zone which may have impacted some of your alarms.',
  memberDstChanged: {
    zero: '{{memberName}} stopped observing daylight savings time. Relevant alarms have been updated.',
    one: '{{memberName}} started observing daylight savings time. Relevant alarms have been updated.'
  },
  impactedAlarms: 'Impacted alarms:',
  alwaysRingParticipantAlarmsOn:
    'Alarms from others will ring unless you decline them.',
  alwaysRingParticipantAlarmsOff:
    'Alarms from others will not ring unless you accept them.',
  agreeToTermsOfServiceAndPrivacyPolicy:
    "By continuing, you accept Galarm's Terms of Service and Privacy Policy.",
  deleteAccountWarningHeader:
    'Warning: This action is irreversible. Deleting your account will:',
  deleteAccountDeleteAlarms: '\u2022 Delete all the alarms that you created',
  deleteAccountDeleteRecipientAlarms:
    '\u2022 Delete all the buddy alarms in which you are the recipient',
  deleteAccountRemoveFromGroups: '\u2022 Remove you from all the groups',
  deleteAccountWarningFooter: {
    zero: `If you are changing phones, then you can just install the app on your new phone and register using the same number. You can remove the app on this phone.

Once you delete your account, you will not be able to restore it.`,
    one: `If you are changing phones, then you can just install the app on your new phone and register using the same number. You can remove the app on this phone.

Once you delete your account, you will not be able to restore it. You may manage your active Galarm subscription on the Play Store.`,
    other: `If you are changing phones, then you can just install the app on your new phone and delete the app on this phone.

Once you delete your account, you will not be able to restore it. You may manage your active Galarm subscription on the App Store.`
  },
  typeConfirmationStringToDeleteAccount:
    'Type {{confirmationString}} to delete your account',
  unableToDeleteAccount: 'Unable to delete account. {{error}}',
  impactedGroups: 'Impacted groups:',
  memberDeletedAccount:
    '{{memberName}} has deleted their account which may have impacted some of your alarms and groups.',
  confirmCountryCodeAndEnterNumber:
    'Please confirm your country code and enter your phone number.',
  confirmCountryCodeAndEnterNumberForExistingUser:
    'Please confirm your country code and enter the phone number that you used while registering earlier.',
  requiredPermissionsDisabledWarningIos:
    'Notifications are disabled. Alarms will not ring. Tap to fix.',
  requiredPermissionsDisabledWarningAndroid:
    "Galarm doesn't have the required permissions to ring alarms. Tap to fix.",
  notificationChannelPopupDisabledWarningAndroid:
    'Alarm notifications will not pop up on the screen. Tap to fix.',
  alarmLockScreenNotificationDisabledWarningAndroid:
    'Alarm notifications will not appear on the lock screen. Tap to fix.',
  why: 'Why',
  how: 'How',
  registrationRequired: 'Registration Required',
  phoneNumberRequired: 'Phone Number Required',
  fewSeconds: 'few seconds',
  lessThanMinute: 'less than a minute',
  updateUnsupportedVersion:
    'An updated version of the app is required to continue using Galarm.',
  update: 'Update',
  back: 'Back',
  unableToMarkAlarmDone:
    'Unable to mark the alarm as done at this time. Please try again in a few moments.',
  unableToMarkAlarmUndone:
    'Unable to mark the alarm as undone at this time. Please try again in a few moments.',
  unableToMarkAlarmUnskipped:
    'Unable to mark the alarm as unskipped at this time. Please try again in a few moments.',
  unableToSkipAlarm:
    'Unable to skip the alarm at this time. Please try again in a few moments.',
  unableToSetGroupAlarmResponse:
    'Unable to {{response}} the group alarm at this time. Please try again in a few moments.',
  unableToDeleteAlarm:
    'Unable to delete alarm at this time. Please try again in a few moments.',
  unableToSetAlarmResponse:
    'Unable to set your response at this time. Please try again in a few moments.',
  unableToSnoozeAlarm: 'Unable to snooze the alarm.',
  unableToStopSnoozeForAlarm: 'Unable to stop snooze for alarm.',
  unableToUpdateParticipantAlarmRingerSettings:
    'Unable to update ringer settings at this time. Please try again in a few moments.',
  updateParticipantPreReminderDuration:
    'Unable to update pre-reminder duration at this time. Please try again in a few moments.',
  unableToUpdateAlarmRingerSettings:
    'Unable to update ringer settings at this time. Please try again in a few moments.',
  unableToUpdateAlarmPreReminderDuration:
    'Unable to update pre reminder duratioon at this time. Please try again in a few moments.',
  retrievingAlarmsFromServer: 'Retrieving alarms from the server...',
  startApp: 'Start >',

  phoneNumberRequiredGroupListHeaderText: `Group is a collection of Galarm contacts allowing you to quickly create alarms with them.

Please register using your phone number to find your contacts who are using Galarm. You can add them to groups.`,
  phoneNumberRequiredBuddyAlarmHeaderText: `Buddy alarm can be used to set alarms for friends and family to remind them of things that they need to do.

To use this and many other cool app features, please register using your phone number.`,
  phoneNumberRequiredContactListHeaderText: `Contacts are people who can be added as participants to alarms.

Please register using your phone number to view your contacts who are using Galarm. You can add them as participants to your alarms.`,
  phoneNumberRequiredGroupAlarmHeaderText: `Group alarm can be used to set a reminder for a group of people.

To use this and many other cool app features, please register using your phone number.`,
  phoneNumberRequiredParticipantsHeaderText: `Participants act as backups to this alarm. They are reminded if you miss the alarm.

To use this and many other cool app features, please register using your phone number.`,
  youHaveNotRegistered: `Your alarms are currently not backed up.

Please register using your phone number. Once you register, your alarms are backed up on the cloud. This allows you to restore your alarms and other settings when you change phones.
`,
  registerNow: 'Register Now',
  hoursAndMinutes: 'Hours and minutes',
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
  years: 'Years',
  daysOfWeek: 'Weekly (select days)',
  monthly: 'Monthly',
  yearly: 'Yearly',
  everyDay: 'Daily',
  selectDaysOfWeek: 'Select days of week',
  alarmRingMessageForSpecificDates:
    'This alarm will only ring in months that have at least {{dayOfMonth}} days. Do you want to continue?',
  alarmRingMessageForSpecificYear:
    'This alarm will only ring in leap years. Do you want to continue?',
  alarmRingMessageForSpecificDayOfWeekInMonth:
    'This alarm will only ring on months that have {{weekNumberOfDayInMonth}} {{dayName}}s. Do you want to continue? ',
  alarmRepetitionAdjustedBasedOnNewDate:
    'Alarm repetition has been updated based on the new date.',
  inviteFriendsToInstallGalarm: `Contacts are people who can be added as participants to your alarms. You can invite contacts:

1. From your address book

2. By manually providing their name and number.

Tap Refresh on the top left to reload Galarm contacts after they register.`,
  whyCreateNewGroup: `Groups are collection of contacts that can be added as participants to your alarms.
  
You haven't created any groups.`,
  noNotifications: `You have no new notifications.`,
  createGroup: 'Create Group',
  galarmRequiresAccessToContactsPermission:
    'Galarm requests permission to access your contacts. This information is sent to its servers to identify your contacts using Galarm. These users can be added as participants to your alarms.',
  galarmRequiresAccessToAudioFilesPermission:
    'Some alarms are using phone music as their ringtone. Please provide permission to access audio files on your device to ring those alarms with the chosen ringtone.',
  galarmRequiresAccessToContactsSelectScreen:
    "Galarm doesn't have permission to access contacts. Please allow access to contacts if you want to use this feature.",
  allow: 'Allow',
  deny: 'Deny',
  galarmRequiresAccessToContacts: `Contacts are people who can be added as participants to your alarms. You can add contacts by:

1. Automatically importing from the phone contacts. Tap "Allow Access" and enable "Contacts".

2. Manually adding their name and number. Tap "Add Contact" to get started.`,
  galarmRequiresAccessToAlarmAndRemindersPermission:
    'Galarm requires "Alarms & reminders" permission so that it can ring alarms exactly at specified times. Please enable it on the next screen.',
  accessToContactsPermissionRequiredAndroid: `Galarm doesn't have permission to access your phone contacts.

When you allow access, people in your phone contacts who are using Galarm will appear as Galarm contacts. Those people can be added as alarm participants. Tap "Allow Access", select "Permission" and enable "Contacts".

You may still manually add contacts to Galarm.`,
  accessToContactsPermissionRequiredIos: `Galarm doesn't have permission to access your phone contacts.

When you allow access, people in your phone contacts who are using Galarm will appear as Galarm contacts. Those people can be added as alarm participants. Tap "Allow Access" and enable "Contacts".

You may still manually add contacts to Galarm.`,
  giveAccess: 'Allow Access',
  enterValidMobileNumber: `Please enter a valid phone number to register.`,
  proceedAnyway: 'Proceed anyway',
  checkInvalidPhoneNumber:
    'We think you have entered an invalid number as {{mobileNumber}}.',
  checkInvalidCountryCode:
    'Invalid country code specified as {{countryCode}}. If more than one country code is present, edit the field to keep the applicable country code.',
  editNumber: 'Edit number',
  editCountryCode: 'Edit Country Code',
  sendingCodeOnCall: 'Calling you with the code.',
  sendCodeOnCall: 'Call Me With the Code',
  registrationLandingScreenRegisterText: `Tap Continue to register using your phone number.

By registering:
1) You will be able to add participants to your alarms and chat with them.
2) Your alarms will be backed up on the cloud such that when you change phones, your alarms will appear on the new phone.`,
  continue: 'Continue',
  registerLater: 'Skip Registration',
  cancelRegistration: 'Cancel Registration',
  welcome: 'Welcome',
  haventReceivedCodeYet: {
    one: `Haven't received the code yet? You can request the code again in 1 second.`,
    other: `Haven't received the code yet? You can request the code again in {{count}} seconds.`
  },
  storagePermissionDenied: `Can't access music files because storage permission is denied.`,
  havingTroubleRegisteringMessage: `Galarm is unable to verify the code after multiple attempts.

Do you want to skip registration and start using the app?`,
  autoDetectVerificationCode: `To simplify registration, Galarm can automatically detect the verification code if you allow Galarm to view SMS messages.`,
  notNow: 'Not Now',
  verifyNumberText: `We will be sending an SMS with the verification code at:

{{formattedMobileNumber}}`,
  verifyNumberCall: `You will receive a phone call with the verification code at:

{{formattedMobileNumber}}`,
  addToContacts: 'Add to Contacts',
  todayString: 'Today',
  tomorrowString: 'Tomorrow',
  backups: 'Backups',
  acintyoTrademark: `\u{00A9} 2024 Acintyo, Inc. All rights reserved.
Galarm is a registered trademark of Acintyo, Inc.
Protected by U.S. Patents 10382613, 10867286.
Other patents pending.`,
  acintyoTrademarkWeb: `\u{00A9} 2024 Acintyo, Inc. All rights reserved. Galarm is a registered trademark of Acintyo, Inc.
Protected by U.S. Patents 10382613, 10867286. Other patents pending.`,
  acintyoTrademarkEnterprise: `\u{00A9} 2024 Acintyo, Inc.
All rights reserved.`,
  markAlarmDone: 'Mark Alarm as Done',
  skipAlarm: 'Skip Alarm',
  confirmGroupAlarm: 'Confirm Participation',
  declineGroupAlarm: 'Decline Participation',
  snoozeAlarm: 'Snooze Alarm',
  deleteAlarmQuickAction: 'Delete Alarm',
  disableAlarm: 'Disable Alarm',
  confirmDisableAlarm: 'Disable Alarm?',
  saveToCalendar: 'Export to Calendar',
  stillRingGroupAlarmAfterConfirmingInAdvance: `You are confirming the group alarm in advance.  Do you still want the reminder at the alarm time?`,
  updateNow: 'Update now',
  nParticipants: {
    one: '1 other participant',
    other: '{{count}} other participants'
  },
  details: 'Details',
  changeParanthesis: '(change)',
  loadMoreHistory: 'View more',
  alarmSummaryForHistory: `Past responses for {{alarmName}}.
Repeats {{alarmRepetitionString}}
{{previousOccurrencesString}}`,
  alarmSummaryForHistoryWeb: 'Past responses for {{alarmName}}',
  alarmSummaryForHistoryRepetitionWeb: 'Repeats {{alarmRepetitionString}}',
  dontSeeCompleteHistory: `Don't see complete history?`,
  dontSeeCompleteHistoryExplanation: `Alarm history is recorded from the time the alarm is created. We only maintain a limited history for each alarm depending on the alarm repetition. For example, if the alarm repeats every day, we maintain a history of 30 days.

Changing the alarm time or repetition resets the alarm history.`,
  noActionAvailableForAlarmOccurrence:
    'No actions available for this occurrence.',
  enterEmailIdForFeedback: 'Used for responding and sharing updates',
  noPreviousOccurrences: 'No alarm history yet',
  nMissedOccurrencesOfAlarmInLastNDays: {
    zero: 'No alarm history yet',
    one: '{{missedAlarmOccurrences}} missed out of last {{count}}',
    other: '{{missedAlarmOccurrences}} missed out of last {{count}}'
  },
  soundAndVibrationSummaryString: {
    zero: '{{volume}}% volume, {{ringtoneName}}',
    one: '{{volume}}% volume, {{ringtoneName}}, Announce Title'
  },
  soundAndVibrationSummaryStringIos: {
    zero: '{{ringtoneName}}',
    one: 'Critical, {{volume}}% volume, {{ringtoneName}}'
  },
  previousOccurrences: 'Alarm History',
  retrieving: 'Retrieving...',
  startTimeOfDay: 'Starts at',
  endTimeOfDay: 'Ends at',
  ringEveryHours: 'Rings every',
  everyNHours: 'Hourly',
  everyMHoursNMinutes: 'Hours and Minutes',
  selectHourlyRepetition: 'Select hourly repetition',
  everyNHoursRepetitionString: {
    one: 'Every hour from {{startTimeString}} to {{endTimeString}} every {{daysString}}',
    other:
      'Every {{count}} hours from {{startTimeString}} to {{endTimeString}} every {{daysString}}'
  },
  everyNHoursAndMinutesRepetitionString:
    'Every {{hoursAndMinutes}} from {{startTimeString}} to {{endTimeString}} every {{daysString}}',
  everyMHoursAndNMinutesRepetitionString: 'Every {{repeatString}}',
  everyNHoursRepetitionStringForCalendar: {
    one: 'Every hour from {{startTimeString}} to {{endTimeString}}',
    other: 'Every {{count}} hours from {{startTimeString}} to {{endTimeString}}'
  },
  everyNHoursAndMinutesRepetitionStringForCalendar:
    'Every {{hoursAndMinutes}} from {{startTimeString}} to {{endTimeString}}',
  everyNHoursAndMinutesRepetitionStringForAlarmSummary:
    'Every {{hoursAndMinutes}} till {{endTimeString}}',
  everyNHoursRepetitionStringForAlarmSummary: {
    one: 'Every hour till {{endTimeString}}',
    other: 'Every {{count}} hours till {{endTimeString}}'
  },
  daysRepetitionOddString: {
    other: 'All odd numbered dates',
    one: 'Odd numbered dates ({{daysString}})'
  },
  daysRepetitionEvenString: {
    other: 'All even numbered dates',
    one: 'Even numbered dates ({{daysString}})'
  },
  oddNumberedRepetitionPreviewString: {
    one: 'Alarm will ring on odd numbered dates that fall on {{daysString}} at the specified time.',
    other: 'Alarm will ring on all odd numbered dates at the specified time.'
  },
  evenNumberedRepetitionPreviewString: {
    one: 'Alarm will ring on even numbered dates that fall on {{daysString}} at the specified time.',
    other: 'Alarm will ring on all even numbered dates at the specified time.'
  },
  hourlyRepetitionPreviewString: {
    one: 'This alarm will ring once every {{daysString}} at:',
    other: 'This alarm will ring {{count}} times every {{daysString}} at:'
  },
  daysRepetitionPreviewString: {
    one: 'This alarm will ring every day.',
    other: 'This alarm will ring every {{count}} days.'
  },
  weeksRepetitionPreviewString: {
    one: 'This alarm will ring every week.',
    other: 'This alarm will ring every {{count}} weeks.'
  },
  everyNDays: {
    one: 'Every day',
    other: 'Every {{count}} days'
  },
  everyNWeeks: {
    one: 'Every week',
    other: 'Every {{count}} weeks'
  },
  everyNMonths: {
    one: 'Every month',
    other: 'Every {{count}} months'
  },
  everyNYears: {
    one: 'Every year',
    other: 'Every {{count}} years'
  },
  cantSetEndTime: `Can't set end time`,
  endTimeShouldBeGreater: 'End time should be greater than start time.',
  cantSetStartTime: `Can't set start time`,
  startTimeShouldBeLess: 'Start time should be less than end time.',
  everyDaySmall: 'day',
  everyGivenDay: '{{day}}',
  everyGivenDays: '{{days}}',
  adjustingAlarmsAfterTimezoneChange:
    'We have detected that you have moved to a different time zone. We are adjusting your alarms.',
  ringOnDays: 'Rings on',
  yourAlarmsMayNotRing: 'Your alarms may not ring',
  yourAlarmsMayNotRingTapToFix: 'Your alarms may not ring. Tap to fix.',
  xiaomiSetupInstructions: `Follow these instructions so that your alarms always ring:

 - Tap "Enable Autostart" and allow "Galarm" so that your alarms always ring. 

To learn more, visit www.galarmapp.com/setup-mi`,
  oppoSetupInstructions: `Follow these instructions so that your alarms always ring:

 - Tap "Enable Autostart" and enable "Allow Auto Startup".

 - Tap "Recent Apps" button on your phone, swipe Galarm app icon down and tap on “Lock”.  

To learn more, visit www.galarmapp.com/setup-oppo`,
  oppo8AndLowerSetupInstructions: `Follow these instructions so that your alarms always ring:

 - Tap "Enable Autostart" and enable "Allow Auto Startup".

 - Tap "Recent Apps" button on your phone, swipe Galarm app icon down and tap on “Lock”.

To learn more, visit www.galarmapp.com/setup-oppo-8-and-lower`,
  vivo9And10SetupInstructions: `Follow these instructions so that your alarms always ring:

 - Tap "Enable Autostart" and allow "Galarm".

 - Tap "Battery Usage", select "High background power consumption" and allow "Galarm".

To learn more, visit www.galarmapp.com/setup-vivo`,
  vivo11SetupInstructions: `Follow these instructions so that your alarms always ring:

- Tap "Enable Autostart" and allow "Galarm".

- Tap "Battery Usage", select "High background power consumption" and allow "Galarm".

To learn more, visit www.galarmapp.com/setup-vivo-11`,
  vivoSetupInstructions: `Follow these instructions so that you alarms always ring:

- Open "Phone Settings > Apps > Special app access > Autostart" and enable autostart for Galarm.

- Open "Phone Settings > Battery > Background power consumption management > Galarm" and choose "Don't restrict background power usage".

To learn more, visit www.galarmapp.com/setup-vivo-12-and-above`,
  vivo8AndLowerSetupInstructions: `Follow these instructions so that you alarms always ring:

 - Tap "Enable Autostart" and allow "Galarm".

 - Tap "Battery Usage", select "High background power consumption" and allow "Galarm".

To learn more, visit www.galarmapp.com/setup-vivo-8-and-lower`,
  huawei7AndLowerAdditionalSetupInstructions: `Follow these instructions so that your alarms always ring:

 - Tap "Protected Apps" and allow "Galarm".

To learn more, visit www.galarmapp.com/setup-huawei-7-and-lower`,
  huaweiAdditionalSetupInstructions: `Follow these instructions so that your alarms always ring:

 - Tap "Manage App Launch" and turn off "Galarm" to "Manage manually". In the prompt that follows, make sure "Auto Launch" and "Run in background" are enabled.

To learn more, visit www.galarmapp.com/setup-huawei`,
  meizuSetupInstructions: `Follow instructions on the link below so that your alarms always ring: https://dontkillmyapp.com/meizu`,
  asusSetupInstructions: `Follow these instructions so that your alarms always ring:

 - Tap "Enable Autostart" and click "Auto-start manager". Enable "Galarm" in the "Auto-start manager".

To learn more, visit www.galarmapp.com/setup-asus`,
  nokia8AndLowerAdditionalSetupInstructions: `Follow these instructions so that your alarms always ring:

 - Tap "Enable Autostart" and allow "Galarm".

To learn more, visit www.galarmapp.com/setup-nokia-8-and-lower`,
  nokiaAdditionalSetupInstructions: `Follow these instructions so that your alarms always ring:

 - Tap "Restricted Apps" and disable "Galarm".

To learn more, visit www.galarmapp.com/setup-nokia`,
  lenovoSetupInstructions: `Follow these instructions so that your alarms always ring:

 - Tap "Recent Apps" button on your phone, locate Galarm app and tap on the “Lock” icon on the top-right corner.
  
To learn more, visit https://dontkillmyapp.com/lenovo`,
  samsungAdditionalSetupInstructions: `Follow these instructions so that your alarms always ring:

 - Tap "Unmonitored Apps" and allow "Galarm".

To learn more, visit www.galarmapp.com/setup-samsung`,
  tecnoSetupInstructions: `Follow instructions on the link below so that your alarms always ring: https://www.galarmapp.com/setup-tecno`,
  infinixSetupInstructions: `Follow instructions on the link below so that your alarms always ring: https://www.galarmapp.com/setup-infinix`,
  samsungAdditionalSetupInstructionsAndroid11AndAbove: `By default, Samsung shows “Brief” notifications which disappear quickly. Follow these instructions to view “Detailed” alarm notifications.

 - Open phone settings and choose "Notifications"
 - Choose “Detailed” notifications style
  
To learn more, visit www.galarmapp.com/setup-samsung-android11-and-above`,
  iosSetupInstructions: `By default, iOS banner style for notifications is set to "Temporary" that causes the notifications to disappear quickly. Follow these instructions to set the banner style to "Persistent".

- Open phone settings and scroll to Galarm
- Choose Notifications and set Banner Style to Persistent

To learn more, visit www.galarmapp.com/setup-ios`,
  iosSetupInstructions15AndBelow: `By default, iOS banner style for notifications is set to "Temporary" that causes the notifications to disappear quickly. Follow these instructions to set the banner style to "Persistent".

- Open phone settings and scroll to Galarm
- Choose Notifications and set Banner Style to Persistent

To learn more, visit www.galarmapp.com/setup-ios-15-below`,
  setNotificationsStyle: 'Set Notifications Style',
  setNotificationsStyleToDetailed: 'Set Notifications Style to "Detailed"',
  setBannerStyle: 'Set Banner Style',
  setBannerStyleToPersistent: 'Set Banner Style to "Persistent"',
  selectAtleastOneDay: 'Select days on which this alarm should ring.',
  selectAtLeastOneHourOrMinute:
    'Either hours or minutes should be a non-zero value',
  hourlyAlarmDefaultStartTimeSetAlert: 'Alarm start time has been set to 8 AM.',
  batteryUsage: 'Battery Usage',
  dontShowAgain: `Don't Show Again`,
  alarmWillRingEveryHourTillSeen:
    'Alarm will snooze every hour until you take some action.',
  unknown: 'Unknown',
  alarmMarkedDone: '{{name}} has marked the alarm "Done".',
  alarmMarkedSkipped: '{{name}} has skipped the alarm.',
  alarmIsMarkedDone: '{{alarmName}} is marked done.',
  alarmIsSkipped: '{{alarmName}} is skipped.',
  alarmIsConfirmed: '{{alarmName}} is confirmed.',
  alarmIsDeclined: '{{alarmName}} is declined.',
  alarmIsSnoozed: '{{alarmName}} is snoozed.',
  alarmIsAccepted: '{{alarmName}} is accepted.',
  alarmIsRejected: '{{alarmName}} is rejected.',
  newContactInfo: `Please provide name and phone number of the contact.

Only registered users can be added as contacts. If the user is not a registered Galarm user, then you can invite them using "Settings > Share This App". You can add them as a contact after they register using their phone number. `,
  cantEditContactMobileNumber:
    'You can’t edit the phone number. Delete the contact and add again if you want to change the number.',
  phoneContactsNotSyncedPermissionDenied:
    "Galarm doesn't have permission to access phone contacts. Phone contacts will not be synced.",
  inviteContactMessage:
    'Hello {{contactName}}, I want to include you in my alarms and reminders using Galarm. Download the app from {{downloadLink}}. Please register using this number and start sharing alarms.',
  noRepeat: 'Does not repeat',
  deepLinkNoLongerValidTitle: 'Oops!',
  deepLinkNoLongerValidMessage: `Alarm was not created. It may be an old link or an invalid link.`,
  inviteContact: 'Invite Contact',
  fromPhoneBook: 'From Address Book',
  addFromPhoneBook: 'Invite From Address Book',
  manually: 'Manually',
  addManualContact: 'Invite Manually',
  noOtherContactsFound: 'All contacts in your address book are using Galarm.',
  cantAddPhoneContactPermissionDenied:
    "Galarm doesn't have permission to access phone contacts. Allow permision to access contacts.",
  alarmWillRingWithRandomRingtone:
    'A random ringtone will be chosen for the alarm.',
  alarmWillRingWithRandomRingtoneGlobal:
    'A random ringtone will be chosen for alarms.',
  createdAlarmFromDeepLinkTitle: `You are all set!`,
  createdAlarmFromDeepLinkMessage: `You will be reminded of "{{alarmName}}" at {{alarmTime}} on {{alarmDate}}`,
  createdRepeatingAlarmFromDeepLinkMessage: {
    zero: `You will be reminded of "{{alarmName}}" {{repetitionString}}`,
    one: `You will be reminded of "{{alarmName}}" {{repetitionString}} at {{alarmTime}} starting {{alarmDate}}`
  },
  deleteCategoryAlertTitle: 'Delete Category?',
  deleteCategoryAlertMessage: {
    zero: '',
    one: '1 alarm belongs to this category. Deleting this category will reset the category on that alarm.',
    other:
      '{{count}} alarms belong to this category. Deleting this category will reset the category on those alarms.'
  },
  updateReadyToInstallAlertTitle: 'Install Update?',
  updateReadyToInstallAlertMessage:
    'Updates are ready to install. Would you like to install the update now? ',
  hideCategoryAlertTitle: 'Hide Category?',
  hideCategoryAlertMessage: {
    zero: 'You will not be able to use this category in future.',
    one: `1 alarm belongs to this category. Hiding this category will reset the category on that alarm.
  
You will not be able to use this category in future.`,
    other: `{{count}} alarms belong to this category. Hiding this category will reset the category on those alarms.
  
You will not be able to use this category in future.`
  },
  alarmCategoryDeleteAllAlarmsPrompt: {
    one: `1 alarm belongs to this category. Do you want to delete it?

Note that participant alarms are not deleted.`,
    other: `{{count}} alarms belong to this category. Do you want to delete them?

Note that participant alarms are not deleted.`
  },
  // Placeholders
  enterNameForGroup: 'Enter a name for the group',
  enterMobileNumber: 'Enter your phone number',
  enterNotesForAlarm: 'Enter notes for alarm',
  enterUserName: 'Enter your name',
  enterEmailId: 'Optional',
  enterFeedback: 'How can we improve?',
  describeProblem: 'Describe your problem',
  invited: 'Invited',
  resend: 'Resend',
  addContact: 'Add Contact',
  inviteAgain: 'Invite Again',
  contactNotRegistered:
    "{{contactName}} hasn't yet installed Galarm. You will be able to add them to alarms once they install and register using their phone number.",
  // Screen Titles
  registration: 'Registration',
  verify: 'Verification',
  profile: 'Profile',
  profileImage: 'Profile Image',
  addProfileImage: 'Add profile image',
  groupDetails: 'Group Details',
  newGroup: 'New Group',
  uploadImage: 'Change Image',
  removeImage: 'Remove image',
  selectParticipants: 'Select Participants',
  selectMembers: 'Select Members',
  selectTimezone: 'Select time zone',
  searchForATimezone: 'Search for a time zone',
  timezone: 'Time Zone',
  deviceTimezone: 'Device time zone',
  specifyTimezoneForAlarm: 'Specify time zone for alarms',
  defaultTimezoneForAlarm: 'Default time zone for alarms',
  changeDefaultTimezoneForAllExistingAlarms: {
    zero: 'Do you want to reset the time zone for all existing alarms?',
    one: 'Do you want to change the time zone for all existing alarms to this time zone? Alarms will ring at the configured time in the new time zone.'
  },
  defaultTimezoneChangedForAllAlarms: `Time zone changed for all alarms.`,
  editName: 'Edit Name',
  groupImage: 'Group Image',
  addGroupImage: 'Add group image',
  selectAlarmType: 'Create an alarm for',
  contactDetails: 'Contact Details',
  commonAlarms: 'Alarms',
  commonGroups: 'Groups',
  groupAlarmsTitle: 'Alarms',
  selectRingtone: 'Select Ringtone', // Used in selecting ringtone and cascading interval
  selectMusic: 'Select Music', // Used in selecting music from user's phone
  select: 'Select', // Used in selecting ringtone and cascading interval
  personalAlarm: 'Personal Alarm',
  groupAlarm: 'Group Alarm',
  newPersonalAlarm: 'New Personal Alarm',
  newGroupAlarm: 'New Group Alarm',
  editPersonalAlarm: 'Edit Personal Alarm',
  editGroupAlarm: 'Edit Group Alarm',
  rearrangeParticipants: 'Rearrange Participants',
  alarmConversation: 'Alarm Chat',
  timezoneMismatchDetected: `Something tells us that you are in a different time zone than the last time you used Galarm.

Please restart Galarm so that we can adapt to the new time zone.`,
  deviceRestartRequired: 'App Restart Required',
  disabledAlarm: 'Disabled alarm {{name}}',
  newContact: 'New Contact',
  contactName: 'Contact Name',
  contactMobileNumber: 'Contact Phone Number',
  enterNameForContact: 'Enter name of the contact',
  pickCountryForContact: 'Country of the Contact',
  enterMobileNumberForContact: 'Enter phone number of the contact',
  specifyNameForContact: 'Contact name is required',
  specifyValidPhoneNumberForContact: 'Valid phone number is required',
  addingContact: 'Adding contact',
  deletingContact: 'Removing contact',
  editingContact: 'Editing contact',
  pending: 'Pending',
  deleteContact: 'Remove Contact',
  galarmContact: 'Added manually',
  phoneContact: 'Added from phone contacts',
  confirmDeleteContactTitle: 'Remove {{name}}?',
  confirmDeleteContact: "You won't be able to add them to your alarms.",
  contactAdded:
    'Great! Now you can add {{name}} as a participant to your alarms.',
  contactInvited:
    'A text message has been sent to {{name}} inviting them to use Galarm.',
  changeSnoozeDuration: 'Change Duration',
  durationOfEvent: 'Duration of the event',
  upgradeToPremium: 'Upgrade to Premium',
  premium: 'Premium',
  alarmLimitReached: 'Alarm Limit Reached',
  unableToConnectToGooglePlay: `Unable to connect to Google Play. Make sure you are logged into your Google account.`,
  cantLoadVideo: `Can't Load Video`,
  cantUpgradeToPremium: `Can't Upgrade to Premium`,
  unableToConnectToIapAndroid:
    'Unable to connect to the Play Store. Please open the Play Store app and make sure that you are logged in.',
  unableToConnectToIapIos:
    'Unable to connect to the App Store. Please open the App Store app and make sure that you are logged in.',
  premiumFeaturesIos: `As a premium user, you get an increased limit of {{maxLimit}} simultaneous alarms.

Your purchase also helps us develop more features.`,
  premiumFeaturesAndroid: `Create unlimited alarms by upgrading to premium.

Your purchase also helps us develop more features.`,
  upgrade: 'Upgrade',

  rvInternalError:
    'We were unable to load the video due to an internal error. However, we have increased your alarm limit by 1.',
  rvNetworkError:
    'Please make sure that you have a good internet connection and try again.',
  rvNotFoundError:
    'We were unable to find a suitable video at this time. However, we have increased your alarm limit by 1.',
  dontRemindMe: "Don't remind me",
  sameTimeAsBuddy: 'Same time as buddy',
  noAlertForBuddyAlarm: 'You chose not to be alerted for this alarm.',
  noAlertRecipientIntervalDescription: 'You will not be alerted.',
  chooseHours: 'Choose hours',
  chooseMins: 'Choose minutes',
  changeHours: 'Change hours',
  moreOptions: 'More options...',
  set: 'Set',
  chooseNumberOfDays: 'Enter number of days',
  chooseNumberOfWeeks: 'Enter number of weeks',
  numberOfDaysShouldBeAtLeastOne: 'Number of days should be at least 1',
  numberOfWeeksShouldBeAtLeastOne: 'Number of weeks should be at least 1',
  numberOfMonthsShouldBeAtLeastOne: 'Number of months should be at least 1',
  numberOfYearsShouldBeAtLeastOne: 'Number of years should be at least 1',
  frequency: 'Frequency',
  configuration: 'Configuration',
  endDate: 'Alarm End Date',
  noEndDate: 'No End Date',
  alarmEndDateSetOnAlarm: 'Alarm end date is set to {{endDateString}}.',
  noAlarmDateSetForAlarm: 'No end date is set for this alarm.',
  noOccurrenceForThisAlarmAsPerEndDate: `End date doesn't allow any occurrences for the alarm.`,
  alarmDateShouldBeBiggerThanAlarmStartDate:
    'Alarm end date should be after alarm start date.',
  aWeek: 'A Week',
  aMonth: 'A Month',
  allInactiveAlarms: 'All Inactive Alarms',
  chooseAlarmEndDate: 'Choose Alarm End Date',
  next: 'Next',
  next7Days: '7 Days from Today',
  next30Days: '30 Days from Today',
  next12Months: '12 Months from Today',
  next10Years: '10 Years from Today',
  chooseDate: 'Pick a Date...',
  learnMore: 'Learn more',
  repeatEvery: 'Repeats every',
  repeatEveryDays: 'day(s)',
  repeatEveryWeeks: 'week(s)',
  repeatEveryMonths: 'month(s)',
  repeatEveryYears: 'year(s)',
  autoSnooze: 'Snooze Alarms Automatically',
  autoSnoozePerAlarm: 'Snooze Alarm Automatically',
  unableToUploadGroupAvatar:
    "Can't upload group image now. Please try again later",
  addNewMembersToExistingAlarms: `You have the following alarms with this group:

{{alarmNames}}
    
Do you want to add the new members to these alarms?`,

  removeGroupMemberAlert: `Removing the member will impact the following alarms:

{{alarmNames}}

Do you still want to remove the member?`,
  leaveGroupAlert: `Leaving the group will impact the following alarms:

{{alarmNames}}

Do you still want to leave the group?`,

  updateAppAfterFeedback:
    'We noticed that you are running an older version of the app. Please update for a better experience.',
  changesAreLost: `You have unsaved changes.

Do you want to save before leaving?`,
  recipientAlarmIntervalGreaterThanRepetitionInterval:
    '"Remind me" interval should be less than the repetition interval.',
  cascadingAlarmIntervalGreaterThanRepetitionInterval:
    '"Time between participant alerts" interval should be less than the repetition interval.',
  subscriptionCanceled:
    'Your premium subscription has been canceled. Premium features will not be available until you renew the subscription.',
  unableToRenewSubscription:
    'There was a problem renewing your premium subscription. Please make sure that your payment information is up-to-date.',
  notice: 'Notice',
  ringerSettingsWillApplyToNewAlarms: `Changes that you make to sound and vibration settings will apply to new alarms only.

For existing alarms, you can edit the alarm to change its sound and vibration settings.`,
  renew: 'Renew',
  galarmPremium: 'Galarm Premium',
  payMonthly: 'Pay monthly at {{price}} per month',
  payYearly: 'Pay yearly at {{price}} per year',
  free: 'Free',
  unlimited: 'Unlimited',
  personalAlarms: 'Personal Alarms',
  groupAlarms: 'Group Alarms',
  buddyAlarms: 'Buddy Alarms',
  alarmRepetitions: 'Repetitions',
  ringtones: 'Ringtones',
  alarmHistory: 'Alarm History',
  base: 'Basic',
  advanced: 'Advanced',
  complete: 'Full',
  alarmHistoryFree: 'Limited',
  phoneMusicAsRingtone: 'Phone Music As Ringtone',
  startFreeTrial: `Start 14-day Free Trial`,
  chargedAtEndOfFreeTrial:
    'You will be charged after the 14-day free trial ends unless you cancel your subscription.',
  monthlySubscription: 'Monthly',
  yearlySubscription: 'Yearly',
  premiumRingtoneRequiresUpgrade:
    'This ringtone requires Premium subscription.',
  defaultPremiumFeaturesDescription:
    'Upgrade to Premium to enable these cool features.',
  groupAlarmsPremiumFeaturesDescription: `You need a premium subscription to create more than 1 group alarm.`,
  buddyAlarmsPremiumFeaturesDescription: `You need a premium subscription to create more than 1 alarm for someone else ("buddy").`,
  shareableAlarmLinksPremiumFeaturesDescription:
    'You need a premium subscription to create more shareable alarm links.',
  viewPremiumFeaturesDescription:
    'Thanks for being a premium user. You can avail these cool features as a premium subscriber.',
  accessToPremiumFeatures:
    'You will get access to these features as part of the subscription:',
  alarmCategoryPremiumFeaturesDescription:
    'You need a premium subscription to create new categories.',
  galarmWebPremiumFeaturesDescription:
    'You need a premium subscription to use Galarm on a web browser.',
  instantAlarmPremiumFeaturesDescription:
    'You need a premium subscription to create more instant alarms.',
  personalAlarmsFeatureDescription:
    'Use a personal alarm for waking up, taking medication, or any other task for yourself. You can optionally add participants who are alerted if you miss an alarm so that they can remind you. The participants are also notified when you mark the alarm done.',
  groupAlarmsFeatureDescription:
    'Use a group alarm for group activities such as outings, parties, or any other event. Alarm goes off at the same time for all the participants and they can confirm their participation.',
  buddyAlarmsFeatureDescription:
    'Create alarms for someone else (“buddy”) to remind them of things they need to do. You are alerted to remind the buddy in case they miss the alarm. You will also be notified once the buddy marks the alarm done.',
  unlimitedAlarmsFeatureDescription: 'Create more group and buddy alarms.',
  instantAlarmsPremiumFeature: 'Send instant alerts',
  instantAlarmsFeatureDescription:
    'Send multiple instant alerts to your contacts and groups.',
  alarmRepetitionsFeatureDescription:
    'Create alarms with advanced repetitions such as every 30 mins, every 2 hours 45 mins, every other day, biweekly, and many more.',
  ringtonesFeatureDescription:
    'Choose from a wide variety of premium ringtones for your alarms. We are continually adding more ringtones to the premium offering.',
  phoneMusicAsRingtoneFeatureDescription:
    'Choose any song/music on your phone as an alarm ringtone.',
  shareableAlarmLinksFeatureDescription: `You can share alarms with others using a link.

For instance, a teacher may share their class alarm link with students using email, WhatsApp or any messaging app. When students tap on the link, a copy of the class alarm will be created on their phones. All students will be alerted at the specified time to join the class.`,
  instantAlarmFeatureDescription: `Instantly alert a group of people with the click of a button.

Use this alarm to alert them of an emergency drill, start of a game, an urgent task/meeting and other such events that require immediate attention.`,

  alarmHistoryFeatureDescription:
    'View all the past responses for each repeating alarm. This allows you to easily find out when you missed your gym class, your mother missed her medication, etc.',
  announceAlarmTitleFeatureDescription:
    'Announce alarm title when the alarm rings.',
  exportToCalendarPremiumFeaturesDescription: `You need a premium subscription to export more alarms to calendar.`,
  exportToCalendarFeatureDescription:
    'You can export alarms to your calendar. This allows you to easily view your alarms on your device calendar and better reflect your availability.',
  galarmWebFeatureDescription: `Use Galarm on a web browser to create, edit, and delete alarms.`,
  preReminderFeatureDescription:
    'Set pre-reminders to get alerted of upcoming alarms. You can configure the relative duration to get alerted prior to the actual alarm time like 30 minutes before, 1 day before, etc.',
  calendarViewFeatureDescription: `View alarms in a calendar to easily see your alarms for any day.`,
  updatePhoneNumberFeatureDescription: `You can change your phone number used for this account. All your alarms, contacts, groups, and other settings will be migrated to the new phone number.`,
  alarmPhotoFeatureDescription:
    'Add a photo to an alarm for quick visual cues such as photo of: a medication, instructions for a task, an item, etc. The photo will be displayed when the alarm rings.',
  taskListFeatureDescription:
    'Create task lists to keep related tasks together.',
  duplicateAlarmPremiumFeature: 'Duplicate Alarm',
  duplicateAlarmFeatureDescription:
    'Quickly create copies of an alarm with the same settings and make changes before saving.',
  calendarViewPremiumFeature: 'Calendar View',
  showingAlarmsForDate: 'Showing alarms for {{date}}',
  updatePhoneNumberPremiumFeature: 'Change Phone Number',
  alarmNotFound: 'Alarm Not Found',
  alarmNotFoundMessage:
    'This alarm may have been recently deleted. Tap on "Remove" to disable further notifications for this alarm.',
  alarmExistsWithDeepLinkTitle: 'Duplicate Alarm',
  alarmExistsWithDeepLinkMessage:
    'An alarm created using this link already exists on your phone.',
  alarmDoesntHaveNextDate: "Alarm doesn't have a valid next date.",
  sharePersonalAlarmDeepLinkDescription:
    'Tap on this link to create an alarm for this event at {{dateString}}.',
  joinGroupAlarmDeepLinkDescription:
    'Tap on this link to join this group event at {{dateString}}',
  subscribedAlarmEdited:
    '{{alarmCreatorName}} has updated {{alarmName}}. Do you want to update your alarm?',
  subscribedAlarmDeleted:
    '{{alarmCreatorName}} has deleted {{alarmName}}. Do you want to delete your alarm?',
  responseReceivedForTicket:
    "We have responded to your ticket #{{ticketId}} at: {{email}}. If it's not in your Inbox, please check your spam folders for an email from support@galarm.zendesk.com.",
  newTicketReceived: `Thanks for writing to us. We have created ticket #{{ticketId}} to track your request.

We will respond at the email address you provided: {{email}}. If this is not correct, please submit your feedback again with the correct email address.

Please also add support@galarm.zendesk.com to your email address book to ensure proper delivery of our responses. We will also send a notification here when we have responded to your request.`,
  sentAccountBackup: `We have sent your alarm data to {{email}}. If it's not in your Inbox, please check your spam folders for an email from noreply@acintyo.com.`,
  alarmDoesntExist: "Alarm doesn't exist.",
  shareAlarm: 'Share Alarm Link',
  shareAlarmDescription: `You can share this alarm with anyone using a link without adding them as a contact. When anyone taps on the link on their phone, a copy of the alarm is created.`,
  shareLink: 'Share Link',
  joinedGroupAlarmFromDeepLinkMessage: `You will be reminded of "{{alarmName}}" at {{alarmTime}} on {{alarmDate}}`,
  joinedRepeatingGroupAlarmFromDeepLinkMessage: {
    zero: `You will be reminded of "{{alarmName}}" {{repetitionString}}`,
    one: `You will be reminded of "{{alarmName}}" {{repetitionString}} at {{alarmTime}} starting {{alarmDate}}`
  },
  youHaveSubscribedToThisAlarm:
    'You are following this alarm from {{creatorName}}.',
  alreadySharedAlarmDescription: {
    zero: `You don't have any followers for this alarm.

You can continue to share this alarm with more people using a link. When they tap on the link, a copy of this alarm will be created on their phone.`,
    one: `{{count}} person is following this alarm.

You can continue to share this alarm with more people using a link. When they tap on the link, a copy of this alarm will be created on their phone.`,
    other: `{{count}} people are following this alarm.

You can continue to share this alarm with more people using a link. When they tap on the link, a copy of this alarm will be created on their phone.`
  },
  shareParentAlarmDescription: `You are following this alarm from {{creatorName}}.

You can also share this alarm with others using a link. When they tap on the link, a copy of this alarm is created on their phone and they can also start to follow this alarm.`,
  shareableAlarmLinks: 'Shareable Links',
  nPeopleFollowingAlarm: {
    zero: `You don't have any followers for this alarm.`,
    one: `{{count}} person is following this alarm.`,
    other: `{{count}} people are following this alarm.`
  },

  likeUsOnFacebook: 'Facebook',
  followUsOnTwitter: 'Twitter',
  followUsOnInstagram: 'Instagram',
  youtube: 'YouTube',
  mediumBlog: 'Blogs on Medium',
  howtoVideos: 'How-To Videos',
  followGalarmOnSocialMedia: 'Follow Galarm on Social Media',
  galarmOnSocialMedia: 'Galarm on Social Media',
  followGalarmOnSocialMediaDescription:
    'View exciting news on app updates, feature usage, upcoming features, as well as key app reviews. You can interact there with the Galarm team as well.',
  sharedAlarm: 'Edit Shared Alarm',
  editingPublishedAlarm: {
    one: `You are editing an alarm that you have shared earlier using a link. {{count}} person is currently following this alarm.

When you make any edits to this alarm, they will be notified about the changes.`,
    other: `You are editing an alarm that you have shared earlier using a link. {{count}} people are currently following this alarm.

When you make any edits to this alarm, they will be notified about the changes.`
  },
  editingSubscribedAlarm:
    'You are following this alarm from {{creatorName}}. You will not receive any updates for this alarm after you edit the alarm.',
  viewAlarm: 'View Alarm',
  view: 'View',
  editAlarm: 'Edit Alarm',
  thanksForTryingPremiumTitle: 'You are all set!',
  thanksForTryingPremiumMessage: `We hope you enjoy the premium features.

Please contact support using "Settings > Feedback" or email us at galarm@acintyo.com if you have any questions.`,
  subscribedAlarmEditedInAlarmDetails: `{{creatorName}} has changed their alarm.
Update your alarm?`,
  subscribedAlarmDeletedInAlarmDetails: `{{creatorName}} has deleted their alarm.
Delete your alarm?`,
  updateSubscribedAlarm: 'Yes, update',
  deleteSubscribedAlarm: 'Yes, delete',
  ignoreSubscribedAlarm: 'No, ignore',
  customRepetitionPremiumScreenDescription: `Custom repetition is a premium feature.

You can use this feature to create alarms with advanced repetitions. Examples include:
`,
  repeatAlternateDate: '\u2705 Repeat on alternate days',
  repeatEveryNDay: '\u2705 Repeat every 3 days',
  repeatEveryNHoursAndMMinutes: '\u2705 Repeat every 2 hours 30 minutes',
  repeatEveryNMinutes: '\u2705 Repeat every 45 minutes',
  repeatBiweekly: '\u2705 Repeat every 2 weeks',
  repeatEveryNWeeks: '\u2705 Repeat every 6 weeks',
  repeatEveryNMonths: '\u2705 Repeat every 3 months (quarterly)',
  repeatEveryLastDayOfMonth: '\u2705 Repeat last day of every month',
  repeatDayOfWeekOfMonth: '\u2705 Repeat on 2nd Saturday of every month',
  repeatLastDayOfWeekOfMonth: '\u2705 Repeat on last Friday of every month',
  customRepetition: 'Custom Repetition',
  lastDayOfMonth: 'Last day of every month',
  dayOfWeekInMonth: '{{weekNumberOfDayInMonth}} {{dayName}} of every month',
  lastDayOfWeekInMonth: 'Last {{dayName}} of every month',
  dayOfWeekInMonthInYearSummary:
    '{{weekNumberOfDayInMonth}} {{dayName}} of {{monthName}}',
  lastDayOfWeekInMonthInYearSummary: 'Last {{dayName}} of {{monthName}}',
  lastDayOfMonthInYearSummary: 'Last day of {{monthName}}',
  dayOfWeekInMonthInYear:
    '{{weekNumberOfDayInMonth}} {{dayName}} of {{monthName}} every year',
  lastDayOfWeekInMonthInYear: 'Last {{dayName}} of {{monthName}} every year',
  lastDayOfMonthInYear: 'Last day of {{monthName}} every year',
  chooseAMonthsConfiguration: 'Please choose a configuration.',
  chooseADaysConfiguration: 'Please choose a configuration',
  chooseAYearsConfiguration: 'Please choose a configuration.',
  sendCodeOnSmsAgain: 'Send Code Again',
  didntGetCodeNewUser: `Didn't get the code?

You can request the code again or skip registration for now. You can register later.`,
  didntGetCodeExistingUser: `Didn't get the code?

You can request the code again or cancel registration. You can register later.`,
  didntGetCodeUpdatePhoneNumber: `Didn't get the code?

You can request the code again or try again later.`,
  alreadySentCodeAgain:
    'We have resent the code to you. If you are having difficulties getting the code, please skip registration for now and register later.',
  theme: 'Theme',
  announceAlarmName: 'Announce Alarm Title',
  ttsHelperText:
    'The alarm title will be announced when the alarm rings. You can tap on the play button in front of the alarm title to hear a preview.',
  initializingTts: 'Initializing text to speech',
  errorSpeakingAlarmName:
    'Unable to speak the alarm title. Please contact support from "Settings > Feedback".',
  entityAlarm: 'alarm',
  entityGroup: 'group',
  entityContact: 'contact',
  admin: 'Admin',
  specifyAlarmTitleToSpeak: 'Specify alarm title to speak',
  wakeup: 'Wake up',
  ttsPremiumScreenDescription: `Announcing alarm title is a premium feature.

When enabled, the alarm title will be announced when the alarm rings.

To preview, provide an alarm title and tap play.`,
  unknownAlarmCategory: 'Unknown alarm category',
  cantAnnounceAlarmTitle: "Alarm title won't be announced",
  ringtoneDurationIsSmall: `Ringtone duration should be at least 5 seconds for the alarm title to be announced.

Do you want to continue with these settings?`,
  noActionItems: 'No action items.',
  personNotInContacts: 'This person is not in your Galarm contacts.',
  light: 'Light',
  dark: 'Dark',
  systemDefault: 'Use Device Setting',
  chooseTheme: 'Choose Theme',
  dontSeeAllYourContacts: "Don't see all your contacts?",
  lastContactsSyncedAt: 'Last synced at {{dateString}}',
  refresh: 'Refresh',
  forever: 'Forever',
  autoSnoozeConfiguration: 'Configure Snooze',
  autoSnoozeConfigurationSummary:
    '{{autoSnoozeDurationLabel}}, {{autoSnoozeCountLabel}}',
  uncategorizedCategoryName: 'Not Categorized',
  uncategorizedCategoryScreenTitle: 'Uncategorized',
  setAlarmCategory: 'Set Alarm Category',
  changeAlarmCategory: 'Change Alarm Category',
  viewAlarmHistory: 'View Alarm History',
  computingAlarmHistory: 'Computing summary for alarm history...',
  alarmByCategories: '{{name}} Alarms',
  alarmCategories: 'Categories',
  editAlarmCategory: 'Edit Category',
  deleteAlarmCategory: 'Delete Category',
  newAlarmCategoryTitle: 'New Category',
  newAlarmCategoryNamePlaceholder: 'Specify a name',
  editAlarmCategoryTitle: 'Edit Category',
  alarmCategorySummary: {
    zero: '{{alarmsSummary}}',
    one: `{{alarmsSummary}}, {{count}} missed`,
    other: `{{alarmsSummary}}, {{count}} missed`
  },
  numAlarms: {
    zero: 'No alarms',
    one: '{{count}} alarm',
    other: '{{count}} alarms'
  },
  numIncidents: {
    zero: 'No incidents',
    one: '{{count}} incident',
    other: '{{count}} incidents'
  },
  openedIncidents: 'Opened incidents',
  closedIncidents: 'Closed incidents',
  incidentChart: 'Incidents Chart (Last 30 days)',
  incidentCalendar: 'Incidents Calendar',
  incidentDistribution: 'Incidents Distribution (Last 30 days)',
  noIncidentsOpenedClosedInLast30Days:
    'No incidents opened or closed in last 30 days',
  noIncidentsOpenedInLast30Days: 'No incidents opened in last 30 days',
  color: 'Color',
  createNewAlarmCategory:
    'Create a new category by tapping the + button on bottom right',
  setValidName: 'Set a valid name',
  specifyTimezoneForAlarmMessage: `You can specify a time zone when you create alarms. The alarms will ring as per the specified time zone. 

If you choose "Device time zone", alarms will ring as per your device time zone.`,
  selectDefaultTimezoneForAlarmDefaultTooltipMessage: `Choose a default time zone for your alarms. You can change the time zone for alarms when you create them.`,
  alarmCategoryFeatureDescription:
    'Create your own categories for alarms. Categories allow you to easily organize your alarms.',
  markAlarmDoneInAdvance: 'Mark alarm "Done" early?',
  skipAlarmInAdvance: '"Skip" alarm early?',
  markAlarmDoneInAdvanceMessageRepeatingAlarm:
    'The alarm will not ring at {{dateTimeString}} if you mark it "Done" now.',
  skipAlarmInAdvanceMessageRepeatingAlarm:
    'The alarm will not ring at {{dateTimeString}} if you skip it now.',
  markAlarmDoneInAdvanceMessageOneTimeAlarm:
    'The alarm will not ring at {{dateTimeString}} if you mark it "Done" now.',
  skipAlarmInAdvanceMessageOneTimeAlarm:
    'The alarm will not ring at {{dateTimeString}} if you skip it now.',
  alarmsByCategories: 'Alarms by Categories',
  workAlarmCategory: 'Work',
  homeAlarmCategory: 'Home',
  birthdaysAlarmCategory: 'Birthdays',
  more: 'More',
  alarmCategory: 'Alarm Category',
  editAlarmCategoryColor: 'Edit Color',
  editAlarmCategoryColorTitle: 'Edit Color for {{name}}',
  allUserCategoriesDeleted:
    'All alarm categories created by you have been removed because the premium subscription was canceled. Alarms in those categories will appear in "Not Categorized".',
  calendar: 'Calendar',
  eventExportedFromGalarm:
    '"Exported from Galarm on {{dateString}} at {{timeString}}."',
  unableToExportEventToCalendar:
    'Unable to export event to the calendar. Please make sure that you have given permission to access calendar on the device. Send us a feedback using "Settings > Feedback" if the problem persists.',
  scanQrCode:
    'To access Galarm on a browser, go to web.galarm.app and scan the QR code.',
  logOut: 'Log Out',
  useHere: 'Use Here',
  appRunningElsewhere:
    'You have been logged out of this session. Would you like to resume this session?',
  dateTime: 'Date & Time',
  selectAtLeastOneDayForRepetition:
    'Choose at least one day on which the alarm should repeat.',
  chooseNumberOfDaysForAlarmRepetition:
    'Enter number of days after which the alarm should repeat.',
  chooseNumberOfWeeksForAlarmRepetition:
    'Enter number of weeks after which the alarm should repeat.',
  chooseNumberOfMonthsForAlarmRepetition:
    'Enter number of months after which the alarm should repeat.',
  chooseNumberOfYearsForAlarmRepetition:
    'Enter number of years after which the alarm should repeat.',
  previouslySelectedContactsAreUnselected:
    'Previously selected contact(s) are unselected.',
  previouslySelectedGroupIsUnselected:
    'Previously selected group is unselected.',
  galarmWebInstructions: `1. Open Galarm app on your phone
2. Go to "Settings" tab and tap on "Galarm Web"
3. Please make sure you are running the latest app if you don't see the option
4. Scan the QR code on the right`,
  likeToCreateAlarm: 'Create an alarm for...',
  payUtilityBills: 'Pay bills',
  wakeupAlarmName: 'Wake up',
  exerciseAlarmName: 'Exercise',
  drinkWaterAlarmName: 'Drink water',
  everyNHoursRepetitionStringShortSummary: {
    one: '{{startTimeString}} to {{endTimeString}} Every hour',
    other: '{{startTimeString}} to {{endTimeString}} Every {{count}} hours'
  },
  firstTimeManagementQuote:
    '"The bad news is time flies. The good news is you’re the pilot."',
  firstTimeManagementQuoteAuthor: 'Michael Altshuler',
  secondTimeManagementQuote: '"All time management begins with planning."',
  secondTimeManagementQuoteAuthor: 'Tom Greening',
  thirdTimeManagementQuote:
    '"Never leave till tomorrow that which you can do today."',
  thirdTimeManagementQuoteAuthor: 'Benjamin Franklin',
  fourthTimeManagementQuote:
    '"Time is what we want the most, but what we use the worst."',
  fourthTimeManagementQuoteAuthor: 'William Penn',
  fifthTimeManagementQuote:
    '"A year from now you may wish you had started today."',
  fifthTimeManagementQuoteAuthor: 'Karen Lamb',
  sixthTimeManagementQuote: '"You may delay, but time will not."',
  sixthTimeManagementQuoteAuthor: 'Benjamin Franklin',
  tapToGetStarted: 'Tap "+" to get started.',
  allCategories: 'All Categories',
  createAlarmWithContact: 'Create an alarm with {{name}}',
  createAlarmWithGroup: 'Create an alarm with {{name}}',
  editGroup: 'Edit Group',
  discardChanges: 'Current changes will be discarded. Continue?',
  alarmWillRingEveryNDays: {
    zero: 'Alarm will ring every day.',
    one: 'Alarm will ring on alternate days.',
    other: 'Alarm will ring every {{dayWithOrdinal}} day.'
  },
  hoursString: {
    zero: '',
    one: '1 hour',
    other: '{{count}} hours'
  },
  minutesString: {
    zero: '',
    one: '1 minute',
    other: '{{count}} minutes'
  },
  alarmWillRingEveryMHoursAndNMinutes:
    'Alarm will ring after every {{repeatString}} starting {{dayString}}. After that, {{otherDaysString}} and so on.',
  alarmWillRingEveryNWeeks: {
    zero: 'Alarm will ring every week.',
    one: 'Alarm will ring biweekly.',
    other: 'Alarm will ring every {{weekWithOrdinal}} week.'
  },
  alarmWillRingEveryNMonths: {
    zero: 'Alarm will ring every month.',
    one: 'Alarm will ring bimonthly.',
    other: 'Alarm will ring every {{monthWithOrdinal}} month.'
  },
  alarmWillRingEveryNYears: {
    zero: 'Alarm will ring every year.',
    one: 'Alarm will ring biyearly.',
    other: 'Alarm will ring every {{yearWithOrdinal}} year.'
  },
  ringParticipantAlarmsByDefaultTooltipMessage: `When this preference is enabled, alarms from others will ring without accepting them. You should still accept the alarm to let the creator know that you intend to participate in the alarm.

When this preference is disabled, alarms added for you by others will not ring on your phone unless you explicitly accept them.`,
  specifyTimezoneForAlarmDefaultTooltipMessage: `When this preference is enabled, you can specify a time zone when you create alarms. The alarms will ring as per the specified time zone.

When this preference is disabled, alarms will ring as per your device time zone.`,
  ringOnEarphoneOnlyTooltipTitle: 'Alarm behavior when earphones are connected',
  ringOnEarphoneOnlyTooltipMessage: `When earphones are connected, you can configure your alarms to either ring on earphones only or on both phone speaker and earphones.

When earphones are not connected, the alarms will ring on phone speaker.`,
  gestureOptionsTooltipTitle: 'Gesture to dismiss an alarm',
  gestureOptionsTooltipMessage: `When an alarm rings on lock screen, you can dismiss it by either tapping a button or sliding a button.

This setting allows you to choose how you want to dismiss an alarm.`,
  notificationSettingsTooltipTitle: 'Notification Settings',
  notificationSettingsTooltipMessage:
    "Enable notifications that you wish to receive and disable the ones that you don't want to be notified about.",
  messagesForAlarm: 'Messages for {{name}}',
  systemNotificationsTooltipMessage: `Notifications generated by the app for events such as:
  - when alarms are affected due to contact changing time zones
  - when an invited contact registers on Galarm
  - when alarms are affected due to someone deleting their account`,
  galarmWebNotificationsTooltipMessage:
    'Notifications sent to you from Galarm Web.',
  alarmsfromOthersNotificationsTooltipMessage:
    'Notifications that are sent when you are added to an alarm as a participant.',
  alarmChatNotifictionsTooltipMessage:
    'Notifications sent for chat messages in an alarm.',
  groupNotificationsTooltipMessage:
    'Notifications that are sent when you are added to a group, removed from a group, or somebody else is added/removed from a group.',
  alarmAcknowledgementNotificationsTooltipMessage:
    'Notifications that are sent when a participant acknowledges an alarm in which you are also a participant.',
  sharedAlarmNotificationsTooltipMessage: `Notifications that are sent:
  - when someone subscribes to an alarm shared by you via a link
  - when an alarm to which you have subscribed has been changed`,
  sleepTimer: 'Sleep Music',
  sleepTimerTitle: 'Play Music for',
  ongoingSleepTimer: 'Sleep Music Ongoing',
  stopSleepTimer: 'Do you want to stop the current music?',
  restartSleepTimer:
    'Do you want to stop the current music and start a new one?',
  play: 'Play',
  youAreOffline:
    'You are currently offline. Go online to perform any operations.',
  alarmLinkRelativeToTimezone: '{{timeString}} in their time zone.',
  alarmLinkNotRelativeToTimezone:
    "Everyone's alarm will ring at {{timeString}}",
  alarmShouldRingAt: "Everyone's alarm will ring at:",
  sleepMusicTooltip: `Play natural sounds to relax your body and mind. Music stops playing after the specified duration.`,
  alarmLinkCopiedToClipboard: 'Alarm link copied to clipboard.',
  confirmLogOut: 'Do you want to log out?',
  appNotRunningMessage:
    "We have detected that Galarm is not running on your phone. Tap to start Galarm such that you don't miss any important alarms.",
  tapToEnterNotesForAlarm: 'Tap to enter notes',
  accountAlreadyExists: 'Existing Account Found',
  accountsMerged:
    'We found an existing account associated with this mobile number. We have merged the two accounts.',
  upgradeToPremiumToAssignUserDefinedCategory:
    'Upgrade to Premium to select user defined category.',
  userDefinedCategoryNotSetForAlarm:
    'Upgrade to Premium to auto-assign user defined category. Category is not set for the alarm.',

  alarmDetails: 'Alarm Details',
  profileDetails: 'Profile',
  activeWebSession: `You already have an active web session. You can log out of that one or start a new session.

Starting a new session will automatically log you out of the currently active web session.`,
  startNewSession: 'Start a New Session',
  confirmWebSessionLogout:
    'This will log you out of your current web session. You can start a new session by scanning the code again.',
  defaultInstantAlarmTitleForGroup: '{{groupName}} Alert from {{creatorName}}',
  defaultInstantAlarmTitleForContact: 'Alert from {{creatorName}}',
  atleastOneMemberShouldBeActive: 'At least one participant should be active.',
  activeParticipants: 'Active Participants',
  acceptAlarm: 'Accept Alarm',
  rejectAlarm: 'Reject Alarm',
  nConfirmed: '{{count}} confirmed',
  nDeclined: '{{count}} declined',
  nPending: '{{count}} pending',
  everyoneConfirmed: 'Everyone has confirmed',
  everyoneDeclined: 'Everyone has declined',
  appTagline: 'Never Miss Anything',
  usePreviousBackup: "I'm a registered user >",
  viewAlarmsByCategories: 'View Alarms by Categories',
  viewAllAlarms: 'View All Alarms',
  switchToSummaryView: 'Switch to Summary View',
  switchToListView: 'Switch to List View',
  hideHourlyAlarms: 'Hide hourly alarms',
  showHourlyAlarms: 'Show hourly alarms',
  calendarView: 'Galarm Calendar',
  viewRecentlyDeletedAlarms: 'View Recently Deleted Alarms',
  deletedOnDate: 'Deleted on {{alarmDate}}',
  recentlyDeletedAlarmFooterText:
    'This alarm has been deleted on {{alarmDate}}',
  noRecentlyDeletedAlarms: 'No recently deleted alarms',
  restoreAlarmsFromMobileApp:
    'These alarms will be automatically deleted after 30 days of their deletion. If you want to restore any of them, please go to your mobile app’s "Recently Deleted Alarms" screen, and tap on their restore icon.',
  deleteInactiveAlarms: 'Delete Inactive Alarms',
  defaultConfigurationUpdated: 'Default configuration has been updated.',
  notAlerted: '(excluded)',
  alarmKeepsRingingHelp: 'Help! My alarm keeps ringing',
  alarmKeepsRingingHelpMessage:
    'We have refreshed your alarms and sent the problem report.',
  alarmKeepsRingingHelpAlert: `We will refresh your alarms and you should no longer observe this problem. We will also send a problem report to our support team for further investigation.

  If the problem persists, please contact us using “Settings > Feedback”.`,
  sendingFeedback: 'Sending feedback',
  sendingProblemReport: 'Sending problem report',
  fixingAlarms: 'Refreshing your alarms',
  existingAccountFound: 'Backup Restored',
  existingAccountDataRestored: {
    zero: 'Congratulations, all data has been restored from backup.',
    one: 'Congratulations, all data has been restored from backup.',
    other: 'Congratulations, all data has been restored from backup.'
  },
  noExistingAccountFound: 'No Backup Found',
  createdNewAccount:
    'This phone number has not been registered with Galarm. We have created a new account using this phone number.',
  iosCloseToAllowedNumNotificationsScheduled: {
    zero: 'iOS allows an app to schedule upto 64 notifications. You have used up all of those notifications. It is possible that you may miss some alarms.',
    one: 'iOS allows an app to schedule upto 64 notifications. You have used up {{numNotifications}} of those notifications.'
  },
  acknowledgeAlarmToScheduleNextOccurrence:
    'This is the last notification for this alarm. Long press this notification and take an action to schedule the next occurrence of this alarm.',
  lastNotificationForOneTimeAlarm:
    'This is the last notification for this alarm.',
  criticalAlertsMessageTitle: 'Ring alarms when your phone is muted',
  criticalAlertsMessageDescription: `
You can configure Galarm to use iOS Critical Alerts to ring alarms when your phone is muted. Would you like to enable it for all alarms?

You can also selectively enable it for some alarms by editing their “Sound and Vibration > Critical Alerts” setting.`,
  allowCriticalAlerts: 'Allow Critical Alerts',
  criticalAlertsPermissionDenied:
    'Galarm requires permission from your phone to show critical alerts. Please enable this permission in your phone settings.',
  enableCriticalAlertsForAllAlarms: 'Yes, enable for all alarms',
  selectivelyEnableCriticalAlerts: "No, I'll selectively enable",

  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  signup: 'Sign Up',
  login: 'Login',
  alreadySignedUp: 'Already signed up?',
  notAUser: 'Not a user?',
  subscribe: 'Subscribe',
  choosePlan: 'Configure Plan',
  billingCycle: 'Billing Cycle',
  needHelpToGetStarted: 'Need help to get started?',
  browser: 'Browser: {{name}}',
  deviceOs: 'Device: {{name}}',
  lastActive: 'Last active: {{timestamp}}',

  signUp: 'Sign up',
  useGalarmOnWeb: 'Use Galarm on Web',
  welcomeUser: 'Welcome {{name}}',
  changePassword: 'Change Password',
  saveAlarm: 'Save Alarm',
  galarmUserTip: 'Did you know?',
  sendAlarm: 'Send Alarm',
  saveGroup: 'Save Group',
  webAppBestViewedOnDesktop: `The URL (web.galarm.app) is for launching a Galarm interface on a web browser on your laptop or desktop.

For using Galarm on this mobile device, please install the Android app from Google Play or the iOS app from the App Store using one of the links below:`,
  scanQrCodeMessage: 'Scan QR Code',
  reloadQrCode: 'Click to reload QR code',
  invalidQrCodeScanned: 'Invalid QR code scanned.',
  unableToScanCode: 'Unable to scan code',
  unableToScanCodeMessage:
    'There was a problem in scanning the code. Please try again. If you continue to have problems, please send us a feedback from the mobile app using "Settings > Feedback".',

  problemWithEnterpriseAccountSetup:
    'There was a problem setting up your Galarm Pro account. Please contact technical support.',
  problemWithCreatingCheckoutSession:
    'There was a problem creating a checkout session. Please contact technical support.',
  problemWithCreatingCustomerPortalSession:
    'There was a problem creating a customer portal session. Please contact technical support.',
  problemWithCreatingApiKey:
    'There was a problem creating an API key. Please contact technical support.',
  creatingAnAccount: 'Creating an account',
  currentPlan: 'Current plan: {{name}} ({{quantity}} seats)',
  myAccount: 'Account Information',
  new: 'New',
  deleteAllAlarmHistoryAlertTitle: 'Delete Alarm History',
  deleteAllAlarmHistoryAlertMessage: `Tap "Continue" to delete history for this alarm. This action can't be undone.`,
  ageOutInactiveAlarmsMessage: `You have {{count}} alarms which have been inactive for more than 60 days. Having a lot of inactive alarms may affect the performance of the app.

Do you want to delete these alarms?`,
  duplicateAlarm: 'Duplicate Alarm',
  graduallyIncreaseVolume: 'Gradually increase volume',
  graduallyIncreaseVolumeTooltipMessage: `When this preference is enabled, alarm volume gradually increases to the configured ringtone volume. This allows you to stop the alarm before it gets too loud.

When this preference is disabled, alarms ring at the configured ringtone volume for the entire ringtone duration.`,
  teams: 'Teams',
  alerts: 'Alerts',
  listeners: 'Listeners',
  noMembersConfiguredInEnterpriseAccount:
    "You haven't added any members yet. Members are users who can be added to the escalation policy of a listener and are alerted for incidents received by the listener. These people should already be registered with Galarm.",
  noTeamsConfiguredInEnterpriseAccount:
    "You haven't created any teams yet. Teams are groups of members can be added to the escalation policy of a listener and are alerted for incidents received by the listener.",
  noAlertsConfiguredInEnterpriseAccount: `You haven't created any listeners yet. You can configure listeners to listen for incidents reported by monitoring tools. Once you create a listener, you can see instructions on how to integrate the listener with various monitoring tools.

You can configure multple escalation levels for each listener.`,
  noShiftsConfiguredInEnterpriseAccount:
    "You haven't created any shift configs yet.",
  noRotationsConfiguredInEnterpriseAccount:
    "You haven't created any rotations yet.",
  rotations: 'Rotations',
  addRotation: 'Add Rotation',
  viewRotation: 'View Rotation',
  enterRotationName: 'Enter rotation name',
  rotationType: 'Rotation Type',
  numberOfHoursInRotation: 'Number of hours in rotation',
  numberOfDaysInRotation: 'Number of days in rotation',
  numberOfWeeksInRotation: 'Number of weeks in rotation',
  specify: 'Specify...',
  noIntegrationsConfiguredInEnterpriseAccount:
    "You haven't configured any integration configs yet.",
  loadingUser: 'Loading user information',
  addMember: 'Add Member',
  add: 'Add',
  noUserFoundWithMobileNumber:
    'No registered Galarm user found with this phone number. Please make sure you enter the phone number with the country code without any special characters such as parentheses, dashes, whitespaces etc.',
  memberWithPhoneNumberAlreadyAdded:
    'Member with this phone number has already been added.',
  memberWithEmailAlreadyAdded: 'Member with this email has already been added.',
  assignKeyToMember: 'Assign license key',
  revokeKeyFromMember: 'Revoke license key',
  cantAssignKeyToMember: "Can't Assign Key To Member",
  noEnterpriseLicenseKeyAvailable:
    'No license keys are available. Please upgrade your plan.',
  addTeam: 'Add Team',
  enterTeamName: 'Enter a team name',
  addAlert: 'Add Listener',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  severity: 'Severity',
  enterAlertName: 'Enter name for the listener',
  organization: 'Organization',
  startTime: 'Start Time',
  endTime: 'End Time',
  shiftName: 'Shift {{index}}',
  addShift: 'Add Shift',
  assignees: 'Assignees',
  shiftStartEndTime: '{{startTime}} - {{endTime}}',
  assignee: 'Assignee',
  specifyAssigneeForShift: 'Specify assignee for shift',
  selectAtLeastOneMember: 'Select at least one member',
  editMember: 'Edit Member',
  editTeam: 'Edit Team',
  cantRemoveMemberAssignedToTeamsOrAlerts: `Member is part of a team or assigned to a listener. Please remove the member from those teams/listeners before deleting.
Teams: {{teams}}
Listeners: {{alerts}}`,
  cantRevokeKeyFromMemberAssignedToTeamsOrAlerts: `Member is part of a team or assigned to a listener. Please remove the member from those teams/listeners before revoking the key.
Teams: {{teams}}
Listeners: {{alerts}}`,
  cantDeleteTeamAssignedToAlerts: `Team is part of a listener. Please remove the team from the listener before deleting.
Listeners: {{alerts}}`,
  cantDeleteRotationAssignedToAlerts: `Rotation is part of a listener. Please remove the team from the listener before deleting.
Listeners: {{alerts}}`,
  editAlert: 'Edit Listener',
  timezoneDisplayString: '(GMT{{offset}}) {{timezone}}',
  getCode: 'Get Code',
  apiKey: 'API Key',
  viewApiKey: 'View API Key',
  apiKeyWarningMessage: `Keep your API key secure at all times. Don't share it with any body. If you think you API key has been compromized, tap on the "Change" button above to change it. After you change the key, you will need to specify the new key in all the places you are triggering incidents using the REST API`,
  integrationConfigs: 'Integration Configs',
  addIntegrationConfig: 'Add Integration Config',
  editIntegrationConfig: 'Edit Integration Config',
  integrationSource: 'Integration Source',
  apiToken: 'API Token',
  integrations: 'Integrations',
  selectPlaceholder: 'Select...',
  zendeskSubdomain: 'Zendesk Subdomain',
  creatorTimeForBuddyAlarm: '{{timeString}} ({{remindMeAfter}})',
  nextFiveAlarms: 'Next 5 Alarms',
  categories: 'Categories',
  viewingAlarmsDueToday: 'Viewing alarms due today',
  viewingAlarmsDueTomorrow: 'Viewing alarms due tomorrow',
  expiredAlarms: 'Expired Alarms',
  viewingExpiredAlarms: 'Viewing Expired Alarms',
  noAlarmsDueToday: 'No alarms due today',
  noAlarmsDueTomorrow: 'No alarms due tomorrow',
  noExpiredAlarms: 'No expired alarms',
  viewingAllAlarms: 'Viewing All Alarms',
  viewingRecentlyDeletedAlarms: 'Viewing Recently Deleted Alarms',
  alarmsWillBeAutomaticallyDeleted:
    'Alarms will be permanently removed 30 days after deletion.',
  summaryView: `Try Summary View?`,
  summaryViewDescription: `See alarms organised by due dates and categories.

You can switch back to the list view using the \u{22EE} button on top-right.`,
  disableBatteryOptimizationMessageOnStartup:
    'Galarm needs to run in the background for alarms to ring reliably. Please allow the permission on the next screen.',
  disableBatteryOptimizationMessage:
    'Galarm needs to run in the background for alarms to ring reliably.',
  allowNotificationsToDisplayAlarms:
    'Galarm needs permission to show notifications when alarms ring.',
  scheduleExactAlarmsMessage:
    'Galarm needs permission to schedule exact alarms.',
  alarmCategoryWithSameNameExists:
    'A category with this name already exists. Please specify a different name.',
  unlimitedAlarmsPremiumFeature: `Unlimited Group and Buddy Alarms`,
  alarmRepetitionsPremiumFeature: 'Advanced Repetition Options',
  ringtonesPremiumFeature: 'More Ringtones',
  galarmWebPremiumFeature: 'Use Galarm on Web',
  taskListPremiumFeature: 'Create Multiple Task Lists',
  preReminderPremiumFeature: 'Pre-Reminder for Alarms',
  alarmCategoriesPremiumFeature: 'Custom Alarm Categories',
  shareableAlarmLinksPremiumFeature: 'Shareable Alarm Links',
  announceAlarmNamePremiumFeature: 'Announce Alarm Title',
  manageSubscription: 'Manage Subscription',
  purchaseRestored: 'Purchase restored.',
  unableToRetrieveSubscriptionPlansAndroid: `We are unable to connect to the Google Play to perform the upgrade. Please make sure you are connected to the internet, and then try again. 

Please report a problem to our support team, if this problem persists.`,
  unableToRetrieveSubscriptionPlansIos: `We are unable to connect to the App Store to perform the upgrade. Please make sure you are connected to the internet, and then try again. 

Please report a problem to our support team, if this problem persists.`,
  drawOverOtherApps: 'Display over other apps',
  drawOverOtherAppsHelperText: 'Allow Galarm to show alarm alerts on top',
  nTimes: {
    one: 'One time',
    other: '{{count}} times'
  },
  learnHowToUseFocus: 'Learn Focus',
  enhancedNotificationsDeprecated: 'Enhanced Notifications Deprecated',
  enhancedNotificationsDeprecatedMessage:
    'We found that Enhanced Notifications are not reliable and cause excessive battery drain. Please use iOS Focus to allow Galarm to ring alarms when your phone is on Do Not Disturb.',
  usingIosFocus: 'Using iOS Focus',
  iosFocusModePara1: `Galarm will not ring when the phone's ringer switch is turned off or when the ringer volume is 0.

Apple recommends using "Focus" when you want to keep your phone silent but still allow some apps to notify you. Use the following instructions to setup Focus on your phone.`,
  iosFocusModePara2:
    'You can view more detailed instructions with screenshots at https://galarm.zendesk.com/hc/en-us/articles/10906890793236-Allow-alarms-to-ring-on-silent-on-iOS',
  settingFocus: 'Setting up Focus:',
  settingFocusFootNotes: `Please note that Focus is available on iOS 15 and above.

For questions or feedback, please contact us using "Settings > Feedback".`,
  enablingFocus: 'Enabling Focus:',
  settingFocusInstruction1: 'Go to "Phone Settings > Focus".',
  settingFocusInstruction2: 'Tap on "Do Not Disturb" focus profile.',
  settingFocusInstruction3: 'Tap on "Apps" and add Galarm.',
  settingFocusInstruction4:
    'Do the same for other Focus profiles that you set up.',

  enablingFocusInstruction1:
    'Swipe down from top right edge to view the Control Center.',
  enablingFocusInstruction2: 'Tap on Focus.',
  enablingFocusInstruction3:
    'Tap on Do Not Disturb or any other Focus profile that you want to enable.',
  enablingFocusInstruction4:
    'Currently enabled Focus appears to be selected. You can tap on it again to disable it.',
  startDateForRotation: 'Start date for rotation',
  startTimeForRotation: 'Start time for rotation',
  member: 'Member',
  multipleShiftsInADay: 'Multiple shifts in a day',
  escalationPolicy: 'Escalation Policy',
  atTimeOfEvent: 'At the time of event',
  afterNMinutes: 'After {{count}} minutes',
  afterNHours: {
    one: 'After 1 hour',
    other: 'After {{count}} hours'
  },
  afterDuration: 'After {{durationString}}',
  addTrigger: 'Add Trigger',
  viewCode: 'View Code',
  apiCodes: 'API Codes',
  viewIncidents: 'View Incidents',
  incidentsForAlert: 'Incidents for listener {{alertName}}',
  timestamp: 'Timestamp',
  acknowledged: 'Acknowledged',
  noIncidentsForAlert: 'No incidents for this listener yet',
  apiCodeCopiedToClipboard: 'API Code has been copied to the clipboard.',
  triggerName: 'Trigger {{index}}',
  delay: 'Delay',
  chooseAssigneePlaceholder: 'Choose Assignee...',
  delayForTrigger: 'Delay for Trigger',
  assigneeWillBeAlertedAfterDelay:
    'Assignee will be alerted after {{durationString}}.',
  escalationForIncidentsForAlert:
    'Escalation for incident at {{incidentDate}} for listener {{alertName}}',
  alertIncidentAcknowledged: 'Acknowledged by {{memberName}} at {{dateString}}',
  alertIncidentRecovered: 'Recovered at {{dateString}}',
  alertIncidents: 'Incidents',
  viewingAlertIncidents: 'Viewing Incidents',
  createOrganization: 'Create Organization',
  youAreNotPartOfAnyOrganization:
    'You are not part of any organization. Create an organization to get started.',
  tellUsAboutYourOrganization: 'Tell us about your organization',
  noActiveSubscription: "You don't have an active subscription.",
  chooseOrganization: 'Choose an organization to continue.',
  noTriggersForIncident: 'No triggers found for this incident.',
  role: 'Role',
  viewer: 'Viewer',
  newOrganization: 'New Organization',
  noOrganizations: 'You are not part of any organization.',
  maxOrganizationsReached: 'User can be part of at most 10 organizations.',
  yourOrganizations: 'Organizations',
  urlNotFound: 'URL does not match any supported route.',
  triggerAlert: 'Trigger Incident',
  recaptchaFailed: 'reCAPTCHA verification failed. {{error}}',
  loadingAccountInformation: 'Loading account information',
  loadingAuthInfo: 'Loading authentication information',
  allAlerts: 'All Listeners',
  recentlyTriggeredAlerts: 'Recently Triggered Listeners',
  recentlyClosedIncidents: 'Recently Closed Incidents',
  noRecentlyTriggeredAlerts: 'No listeners have been triggered recently.',
  noRecentlyClosedIncidents: 'No incidents have been closed recently.',
  closedAt: 'Closed at {{dateTimeString}}',
  dashboard: 'Dashboard',
  loggedIntoOrganizationAccount:
    '(organization: {{organizationName}}, role: {{role}})',
  viewIncidentEscalation: 'View Incident Escalation',
  closeIncident: 'Close Incident',
  closingIncident: 'Closing incident',
  unableToRefreshEnterpriseAccount: 'Unable to refresh your account.',
  viewAuthToken: 'View Auth Token',
  problemWithCreatingAuthToken:
    'There was a problem creating an auth token. Please contact technical support.',
  authToken: 'Auth Token',
  authTokenWarningMessage: `Keep this auth token secure at all times. Don't share it with any body. If you think you API key has been compromized, tap on the "Change" button above to change it. After you change the token, you will need to specify the new token in all the places you are triggering incidents using the REST API`,
  authTokenCopiedToClipboard: 'Auth token has been copied to the clipboard.',
  resendVerificationEmail: 'Resend Verification Email',
  loggedInUsingEmail: 'Logged in using email {{email}}.',
  checkingIfMemberIsAppUser: 'Checking if member is an app user.',
  settingMemberDetails: 'Setting member details.',
  addingTeam: 'Adding team.',
  editingTeam: 'Editing team.',
  addingRotation: 'Adding rotation.',
  editingRotation: 'Editing rotation.',
  assigningKeyToMember: 'Assigning key to member.',
  revokingKeyFromMember: 'Revoking key from member.',
  deletingTeam: 'Deleting team.',
  deletingRotation: 'Deleting rotation.',
  addingAlert: 'Adding listener.',
  editingAlert: 'Editing listener.',
  deletingAlert: 'Deleting listener.',
  triggeringAlert: 'Triggering incident.',
  creatingOrganizationAccount: 'Creating organization account.',
  creatingCheckoutSession: 'Launching your checkout session.',
  creatingAnAccountForMember: 'Creating an account for member.',
  passwordResetEmailSent:
    'We have sent you an email to reset your password. Change your password and login again.',
  changeOwner: 'Change Owner',
  changeOrganizationOwner: 'Change Organization Owner',
  newOwnerEmail: 'New Owner Email',
  saveOnYearlySubscription: 'Save {{percent}}%',
  annual: 'Annual',
  resetAlarms: 'Refresh Alarms',
  resetAlarmsAlert:
    'All alarms have been refreshed. If you continue to observe problems with the alarms, please submit a ticket using "Report a Problem" option.',
  resetAlarmsTooltipMessage:
    'If you are experiencing problems with alarms such as deleted alarms ringing on the phone, please refresh alarms using this option. If the problem persists, please submit a ticket using "Report a Problem" option.',
  resetTempPassword: 'Reset Temporary Password',
  currPassword: 'Current Password',
  tempPassword: 'Temporary Password',
  newPassword: 'New Password',
  reset: 'Reset',
  loggingIn: 'Logging in',
  resettingTempPassword: 'Resetting temporary password',
  changingPassword: 'Changing password',
  numSeats: 'Number of Seats',
  helpMeDecide: 'Help me decide',
  numEnterpriseSeatsHelper:
    'You should choose the number of seats as the number of people in your organization who will be using the app to receive incidents. While configuring an listener, you can specify its escalation policy. You can have mulltiple levels in an escalation policy. For example, you can specify that the first level of escalation is to a team of 3 people. If the first level of escalation does not respond within 5 minutes, the second level of escalation is to a team of 5 people. You can specify the number of seats as 8 in this case.',
  numBusinessSeatsHelper:
    'Choose the number of seats to match the number of people who will be using Galarm to receive alarms and reminders.',
  perSeatPrice: '{{price}} per {{interval}} per seat',
  doneForToday: 'Acknowledged',
  getStarted: 'Get Started',
  firstAlertInstructions: 'First Listener Instructions',
  learnMoreAboutGalarmEnterprise: 'Learn more about Galarm Pro',
  home: 'Home',
  team: 'Team',
  alert: 'Alert',
  listener: 'Listener',
  forgotPassword: 'Forgot Password?',
  forgotPasswordTitle: 'Forgot Password',
  sendPasswordResetEmail: 'Send Password Reset Email',
  sendingPasswordResetEmail: 'Sending password reset email',
  field: 'Field',
  description: 'Description',
  requestIntegration: 'Request another integration',
  integrationDetails: 'Integration Details',
  enterIntegrationDetails: 'Provide integration details',
  galarmEnterpriseFreeTrial: 'Sign-up for a 14-day free trial',
  galarmEnterpriseFreeTrialDescription: 'Automate incident management',
  bySigningUpYouAgreeToOur: 'By signing up, you agree to our',
  and: 'and',
  alarmSubscribers: 'Alarm Followers',
  otherSubscribersCount: {
    one: '1 more follower not in your contacts',
    other: '{{count}} more followers not in your contacts'
  },
  getAccountData: 'Export Alarm Data',
  getAccountDataPrompt:
    'Provide an email address where we will send a text file containing all your alarms.',
  getAccountDataConfirmation:
    'Please note that we will be emailing you a text file containing all your alarms at {{email}}.',
  sendingAccountData: 'Exporting alarm data...',
  sentAccountData: 'Alarm data sent to {{email}}.',
  errorSendingAccountData: 'Error exporting alarm data: {{error}}',
  sendingEmailVerificationLink: 'Sending email verification link',
  viewLogs: 'View Logs',
  message: 'Message',
  billing: 'Billing',
  newIncidentForAlert: 'New incident for listener {{alertName}}',
  clickNextAfterAddingMembers: 'Click "Next" after adding members',
  clickDoneAfterAddingAlerts:
    'Click "Done" to complete the setup and go to Dashboard.',
  alertCreatedSuccessfully:
    'Listener has been created successfully. You can trigger this listener from the three-dot menu icon in front of the listener name.',
  fixSubscription: 'Fix Subscription',
  fixSubscriptionDescription:
    'We have detected a problem with your subscription. Your subscription contains {{numSeats}} seat(s) but you have assigned keys to {{numSeatsAssigned}} member(s). You can assign at most {{numSeats}} key(s).',
  fixSubscriptionChooseOption:
    'Please fix your subscription using one of the options below.',
  fixSubscriptionContactAdmin:
    'Please contact your organization administrator.',
  unassignKeysFromMembers:
    'Unassign at least {{extraKeysAssigned}} key(s) from members',
  addMoreSeatsToSubscription:
    'Add at least {{extraKeysAssigned}} seat(s) to your subscription',
  revokeMembersKey: "Revoke Members' Key",
  revokeMembersKeyDescription:
    "Choose the members whose keys you'd like to revoke.",
  revoke: 'Revoke',
  unableToRevokeMembersKey:
    "There was an error revoking members' key. Please contact support.",
  problemWithLoadingProducts: 'Problem with loading products. {{error}}',
  verificationCompleted: 'Verification Complete',
  deviceAlreadyRegistered:
    'This phone number has previously been verified on this device.',
  phoneNumberAutoFilled:
    'Galarm will use the selected phone number from your Google account.',
  unableToRegisterPreVerifiedUser: 'Unable to register pre-verified user.',
  allowNotifications: 'Allow Notifications',
  allowNotificationsDescription: 'To display alarms when they ring.',
  scheduleExactAlarms: 'Schedule Alarms',
  scheduleExactAlarmsHelperText:
    'Allow Galarm to ring alarms at the exact time.',
  scheduleExactAlarmsDescription: 'To ring alarms at the exact time.',
  disableBatteryOptimization: 'Manage Battery Optimization',
  disableBatteryOptimizationDescription:
    'To allow Galarm to run in the background.',
  requestRequiredPermissions:
    'Galarm requires the following permissions to ring alarms',
  allowDisallowedPermissions: 'Tap to allow',
  notificationsAreEnabled:
    'Permission to show notifications is already allowed.',
  connectingToStore: 'Connecting to store...',
  restartApp: 'Restart App',
  updateAvailable: 'Update Available',
  english: 'English',
  russian: 'Russian',
  german: 'German',
  french: 'French',
  spanish: 'Spanish',
  italian: 'Italian',
  portuguese: 'Portuguese',
  japanese: 'Japanese',
  korean: 'Korean',
  indonesian: 'Indonesian',
  changeAppLanguage: 'Change App Language',
  appLanguage: 'App Language',
  iosChangeAppLanguagePara1: `Galarm picks the app language from your phone settings. Please follow these steps to change the app language for Galarm:`,
  iosChangeAppLanguageInstruction1:
    'Open “Phone Settings > General > Language & Region”. Make sure that the language that you want to use for Galarm is present in the list of preferred languages.',
  iosChangeAppLanguageInstruction2:
    'Go back to “Phone Settings" and tap on "Galarm" in the list of apps at the bottom.',
  iosChangeAppLanguageInstruction3:
    'Tap on “Language" and select the language that you want to set for Galarm. Come back to the app after picking the language',
  androidChangeAppLanguagePara1: `Galarm picks the app language from your phone settings. Please follow these steps to change the app language for Galarm:`,
  androidChangeAppLanguageInstruction1:
    'Tap on "Open App Settings" button below.',
  androidChangeAppLanguageInstruction2:
    'Tap on "Language" and select the language that you want to use for Galarm.',
  androidChangeAppLanguageInstruction3:
    'After picking the language, come back here and click on the “Restart App” button below.',
  problemRetrievingSubscriptionData:
    'There was a problem retrieving your subscription information. We are routing you to the account page. Please check your subscription status and contact technical support if you continue to have problems.',
  deletingOrganization: 'Deleting organization...',
  deleteOrganization: 'Delete Organization',
  deleteOrganizationDescription: `Are you sure you want to delete your organization?
  
  This is an irreversible operation. All your organization's data will be deleted.
  
  Please make sure that you have canceled your subscription before deleting the organization.`,
  galarmEnterpriseTrialDescription:
    'There is a 14-day free trial during which you can cancel the susbcription without any charge.',
  galarmEnterpriseTrialAlreadyAvailed:
    'You have already availed the 14-day free trial. Contact support if you need more time to evaluate the product.',
  signinWithGoogle: 'Sign in with Google',
  signinWithGithub: 'Sign in with GitHub',
  verifyAccount: 'Verify Account',
  verifyAccountMessage: `You should have received an email with a verification code. Enter the code below to verify your account. If you haven't received the code, you can request a new code by clicking on "Resend Code" button.`,
  verificationCode: 'Verification Code',
  optionalString: '{{string}}',
  quickReminderTitle: 'Title (optional)',
  accountVerified: 'Account Verified',
  accountVerifiedMessage: 'Please log in using your credentials.',
  multipleUsersWithSameEmail:
    'There are multiple users with same email. Please contact support.',
  multipleUsersWithSameMobileNumber:
    'There are multiple users with same mobile number. Please contact support.',
  startTrial: 'Start Trial',
  nextSteps: 'Next Steps',
  firstTimeSetupComplete:
    'You have completed the initial setup for Galarm Enterprise and created your first listener. Following are some next steps that you can take from here.',
  testTheAlertInstruction:
    'Check the instructions at {{link}} to know how to test the listener that you just created.',
  integrateTheAlertInstruction:
    'Check the instructions at {{link}} to integrate the listener with various monitoring tools.',
  createMoreAlertsInstruction:
    'You can add more listeners by going to the "Listeners" tab on the left.',
  addMoreMembersInstruction:
    'You can add more members by going to the "Members" tab on the left.',
  createTeamsInstruction:
    'You can group members into "Teams" and alert a whole teams when an incident occurs. Create teams by going to the "Teams" tab on the left and use them in the listener\'s configuration.',
  testTheAlert: 'Test the Listener',
  integrateTheAlert: 'Integrate the Listener',
  createMoreAlerts: 'Create More Listeners',
  addMoreMembers: 'Add More Members',
  createTeams: 'Create Teams',
  docs: 'Docs',
  contactSupport: 'Contact Support',
  pastDue: 'Past Due',
  canceled: 'Canceled',
  unpaid: 'Unpaid',
  incomplete: 'Incomplete',
  incomplete_expired: 'Incomplete Expired',
  trialing: 'Free Trial',
  paused: 'Paused',
  plan: 'Plan',
  status: 'Status',
  renewsOn: 'Renews On',
  yourRoles: 'Your Roles',
  enterprisePlan: '{{quantity}} seats, renews every {{interval}}',
  currentOrganization: 'Current Organization',
  useThisOrganization: 'Use this organization',
  fixPayment: 'Fix Payment',
  fixingPayment: 'Fixing payment',
  addPaymentInformation: 'Add Payment Information',
  noPaymentInformationAvailable:
    'No payment information available. Please refresh the page if you have recently added payment information.',
  openBillingPortal: 'Open Billing Portal',
  startupWizardDescription:
    'This wizard will help you get started and create your first listener.',
  userNotFound: 'User not found',
  wrongPassword: 'Wrong password',
  emailAlreadyInUse: 'Email already in use',
  restoreDeletedAlarms: 'Restore Deleted Alarms',
  deleteAlarmAlert: `Deleted alarms can be restored from "Recently Deleted Alarms". You can view them by tapping the three-dot menu (⋮) on the top-right of the "Alarms" tab.

Deleted alarms stay there for 30 days after which they are permanently deleted.`,
  slackVerificationCode:
    'Your Slack Verification Code is {{verificationCode}}.',
  dndAccess: 'Do Not Disturb Access',
  dndHelperText:
    'Allow Galarm to ring alarms when your phone is on Do Not Disturb.',
  cantRestoreInstantAlarmMessage:
    "Instant alarms can't be restored. You can view chat and other information for this alarm if needed. This alarm will be permanently removed after 30 days since deletion.",
  upgradeToUnlockAllFeatures: 'Upgrade to unlock all features',
  otherSignupOptions: 'Other Signup Options:',
  renewSubscription: 'Renew Subscription',
  subscriptionOnHold: `On-hold (Your plan allows {{subscriptionQty}} seats
but you have assigned {{numSubscriptionKeys}} seats.`,
  checklists: 'Tasks',
  checklist: 'Task List',
  typeToAddTask: 'Type to add task',
  specifyTaskName: 'Specify task name',
  otherChecklists: 'Task Lists',
  defaultChecklistName: 'untitled',
  addTasksToChecklist: 'Add tasks using the input box at the bottom',
  addTasksToDefaultChecklist:
    'Add tasks using the input box at the bottom...or start a new task list by clicking the + button on top right',
  createNewChecklist:
    'You can create new task lists to keep track of related tasks at one place.',
  addNewChecklist: 'New Task List',
  alarmPhoto: 'Alarm Photo',
  alarmPhotoFeatureTitle: 'Alarm Photo',
  alarmPhotoFeatureDescriptionPremiumUser: `Using this feature, you can add a photo to an alarm for quick visual cues such as photo of: a medication, instructions for a task, an item, etc. The photo will be displayed when the alarm rings.`,
  alarmPhotoFeatureDescriptionFreeUser: `Using this feature, you can add a photo to an alarm for quick visual cues such as photo of: a medication, instructions for a task, an item, etc. The photo will be displayed when the alarm rings.
  
This is a premium feature. You will be asked to upgrade to premium in order to use this feature.`,
  alarmPhotoPremiumFeature: 'Add Photo to Alarm Notes',
  installPendingAppUpdate:
    'Latest app update has been downloaded and is ready to be installed.',
  numTasks: {
    zero: 'No tasks',
    one: '1 task',
    other: '{{count}} tasks'
  },
  numTasksWithCompleted: {
    one: '1 task, {{completed}} completed',
    other: '{{count}} tasks, {{completed}} completed'
  },
  muteAlarms: 'Mute Alarms',
  unmuteAlarms: 'Unmute Alarms',
  alarmsAreMuted: 'All your alarms are currently muted. Tap to unmute.',
  alarmsHaveBeenMuted:
    'Your alarms have been muted. They will not ring until you unmute them.',
  alarmsHaveBeenUnmuted:
    'Your alarms have been unmuted. They should ring as configured.',
  deleteChecklist: 'Delete Task List',
  confirmDeleteChecklist: 'Delete Task List?',
  confirmDeleteCompletedTasks: 'Delete Completed Tasks?',
  confirmResetCompletedTasks: 'Reset Completed Tasks?',
  confirmDeleteTask: 'Delete {{taskName}}?',
  showCompletedTasks: 'Show Completed Tasks',
  hideCompletedTasks: 'Hide Completed Tasks',
  deleteCompletedTasks: 'Delete Completed Tasks',
  resetCompletedTasks: 'Reset Completed Tasks',
  yourGroups: 'Your Groups',
  newAlarmWith: 'New Alarm with {{name}}',
  newGroupWith: 'New Group with {{name}}',
  alertContact: 'Alert {{name}}',
  alertGroup: 'Alert {{name}}',
  instantAlarmMovedToFabNotice:
    'The Instant Alarm feature is now available from the “+” button at the bottom right of this screen. The “loudspeaker” icon will be removed from the top-right in a future release.',
  tasksIntroTitle: 'Tasks and Task Lists',
  tasksIntroDescription: `Tasks are your to-dos that don’t have a deadline. You can quickly add and manage them here, checking them off as you handle each task.

You can also create task lists to group similar tasks. For example, you can create a task list for your grocery shopping, another for your work tasks, and so on.`,
  groupsMovedToContactsScreen:
    '"Groups" have been merged into this tab. Your groups appear on the top under "Your Groups". You can create new groups by tapping on the "+" button on the bottom right.',
  loadingTasks: 'Loading tasks...',
  tryPremium: 'Try Premium',
  buyPremiumToUnlockFeature:
    'This is a premium feature. Unlock this and many other cool features with our premium plan that comes with a 14-day free trial.',
  newTask: 'New Task',
  newTaskList: 'New Task List',
  enterTaskName: 'Enter task name',
  enterTaskListName: 'Enter task list name',
  taskName: 'Task Name',
  taskListName: 'Task List Name',
  checklistTasks: '{{name}}',
  editTaskListName: 'Edit task list name',
  editTask: 'Edit task',
  releaseNotes: 'Release Notes',
  currentRelease: '(currently installed)',
  loadingReleaseNotes:
    'Loading release notes. Please make sure you are connected to the internet.',
  id: 'ID',
  subscriptionData: 'Subscription Data',
  organizations: 'Organizations',
  showRotationCalendar: 'Show Rotation Calendar',
  rotationCalendar: 'Rotation Calendar',
  editShift: 'Edit Shift',
  startDateForShift: 'Start date for shift',
  startTimeForShift: 'Start time for shift',
  endDateForShift: 'End date for shift',
  endTimeForShift: 'End time for shift',
  editingShift: 'Editing shift',
  addingShift: 'Adding shift',
  products: 'Products',
  prices: 'Plans',
  planType: 'Plan Type',
  business: 'Business',
  enterprise: 'Enterprise',
  compare: 'Compare',
  apiSupport: 'API Support to monitor services',
  accessToConsoleToViewAndCloseIncidents: 'Dashboard to manage incidents',
  triggerInstantAlertsFromTheConsole:
    'Trigger incidents from the Enterprise Console',
  coreFeatures: 'Core Features:',
  everythingInBusiness: 'Everything in Business plan and:',
  updatePlanInstructions:
    'You can update your plan by clicking on the "Open Billing Portal" button on right and choosing "Update Plan" button on the billing portal.',
  membersDescription: `You have purchased {{subscriptionKeysCount}} seats -- {{assignedSubscriptionKeysCount}} assigned and {{unassignedSubscriptionKeysCount}} available. A checkmark in front of a member's name indicates an assigned seat.`,
  centralBilling: 'Central Billing',
  saveTwoMonths: 'Save 2 months',
  owner: 'Owner',
  enterpriseAccountSummary:
    'You are part of organization {{organizationName}} enrolled in the Galarm Pro {{planType}} plan.',
  perYearPerUser: 'per user/year',
  perMonthPerUser: 'per user/month',
  creationDate: 'Creation Date',
  updatePhoneNumber: 'Change Phone Number',
  confirmCountryCodeAndEnterUpdatedPhoneNumber: `Please confirm the country code and enter your new phone number. Your account will be linked to this phone number after you finish verification.`,
  unableToUpdatePhoneNumber:
    'Unable to change phone number: {{error}}. Please contact support.',
  phoneNumberUpdated: 'Phone number changed successfully.',
  phoneNumberUpdatedMessage:
    'You can start using the app normally. Plesae ask your contacts to update your phone number in their address book.',
  memberChangedPhoneNumber:
    '{{memberName}} has changed their phone number. Please update your address book to continue adding them to your alarms.',
  preferredSourceForIncidents: 'Preferred Source for Incidents',
  apiCodesDescription:
    'Use the following instructions to integrate the listener with a source',
  rotation: 'Rotation',
  noAlarmsForThisDay: 'No alarms for this day',
  markAlarmsAsComplete: 'Mark alarms as complete',
  markAlarmsAsCompleteExplanation: `One time alarms will be marked as complete and will appear in the "Inactive" alarms section.

Repeating alarms will be marked as complete and will be moved to the next occurrence.`,
  enterIncidentPrefix: 'Max 5 characters',
  incidentPrefix: 'Incident Prefix',
  incidentPrefixAlreadyExists: 'Incident prefix already exists',
  removeTask: 'Remove Task?',
  incidentPrefixLengthExceeded: 'Incident prefix is longer than 5 characters',
  criticalAlerts: 'Critical Alerts',
  criticalAlertsConfiguration:
    'Alarm will ring at {{criticalAlertsVolume}}% volume even when your phone is muted or a Focus is on.',
  criticalAlertsDisabledShort: 'Alarm will not ring when your phone is muted.',
  criticalAlertsConfigurationGlobal:
    'Alarms will ring at {{criticalAlertsVolume}}% volume even when your phone is muted or a Focus is on.',
  criticalAlertsDisabledShortGlobal:
    'Alarms will not ring when your phone is muted.',
  criticalAlertsEnabled:
    'Critical Alerts are enabled. Alarms will ring even when your phone is muted or a Focus is on.',
  criticalAlertsDisabled:
    'Critical Alerts are disabled. Alarms will not ring when your phone is muted.',
  enableCriticalAlerts: 'Enable Critical Alerts',
  showFullScreenIntents: 'Show Alarm on Full Screen',
  showFullScreenIntentsDescription:
    'Alarm wil appear on full screen when phone is locked.',
  percentVolume: '{{volume}}% volume',
  selectADate: 'Please choose a date to view alarms.',
  affiliates: 'Affiliates',
  referralCount: 'Referral Count',
  addAffiliate: 'Add Affiliate',
  creatingAffiliate: 'Creating Affiliate',
  affiliateLink: 'Affiliate Link',
  upgradeRequiredForWeb: 'Upgrade Required',
  upgradeToPremiumForWeb:
    'This feature requires a premium subscription. Please purchase a premium subscription from the mobile app to use this feature.',
  accountType: 'Account Type',
  profileAndSettings: 'Profile and Settings',
  confirmIncidents: 'Confirm Multiple Incidents',
  removeIncidents: 'Remove Multiple Incidents',
  incidents: 'Incidents',
  closeSelectedIncidents: 'Close Selected Incidents',
  viewIncidentNotes: 'View Incident Notes',
  incidentNotes: 'Incident Notes',
  addNote: 'Add Note',
  noAdditionalNotesForIncident: 'No additional notes for this incident.',
  fromIncident: 'From incident:',
  fromUsers: 'From users:',
  database: 'Database',
  chooseFirstDayOfWeek: 'Choose first day of week',
  firstDayOfWeek: 'First day of week',
  changePlan: 'Change Plan',
  currentPremiumSubscriptionStatus:
    'You are currently subscribed to the {{planName}} plan.',
  currentProSubscriptionStatus:
    'You are a member of the {{orgName}} organization.',
  changeToAnnualPlan: 'Change to the Yearly plan',
  unableToChangePlan:
    'Unable to change to the yearly plan. Please contact technical support using "Settings > Feedback".',
  confirmChangeToAnnualPlan: 'Do you want to change to the Yearly plan?',
  changeToAnnualPlanDescription:
    'You will be charged {{price}} for the yearly plan. Any remaining days in your current plan will be adjusted to the yearly plan.',
  alarmTimezoneChanged:
    'Some alarms were updated to use device time zone because the time zone used for those alarms is not supported by this device.',
  defaultTimezoneChanged:
    'Default time zone for alarms is changed to device time zone because {{defaultTimezoneForAlarm}} time zone is not supported by this device.',

  // Ringtone names
  summer_soft: 'Summer Soft',
  beeps: 'Beeps',
  bollywood_nights: 'Bollywood Nights',
  gentle_reminder: 'Gentle Reminder',
  himalayan_piper: 'Himalayan Piper',
  holiday_joy: 'Holiday Joy',
  hollywood_adventure: 'Hollywood Adventure',
  melody: 'Melody',
  morning_rooster: 'Morning Rooster',
  morning_sunshine: 'Morning Sunshine',
  sweet_sea: 'Sweet Sea',
  old_school_ring: 'Tring Tring',
  horizon: 'Horizon',
  symphony: 'Symphony',

  // Notifications
  MyAlarmNotification: "{{alarmName}} - It's time.",
  ParticipantCascadingAlarmNotification:
    "{{alarmName}} - It's time to remind {{alarmCreatorName}}.",
  ParticipantSimultaneousAlarmNotification:
    "{{alarmName}}. It's time to confirm your participation.",
  InstantAlarmNotification:
    '{{alarmName}}. Your immediate attention is requested.',
  MyAlarmNotificationShort: "It's time.",
  ParticipantCascadingAlarmNotificationShort:
    "It's time to remind {{alarmCreatorName}}.",
  ParticipantSimultaneousAlarmNotificationShort:
    "It's time to confirm your participation.",
  InstantAlarmNotificationShort: 'Your immediate attention is requested.',
  MyRecipientAlarmNotification:
    "{{alarmName}} - It's time to remind {{alarmRecipientName}}.",
  MyRecipientAlarmNotificationShort:
    "It's time to remind {{alarmRecipientName}}.",
  RecipientAlarmNotification:
    "{{alarmName}} (by {{alarmCreatorName}}) - It's time.",
  RecipientAlarmNotificationShort: "It's time - by {{alarmCreatorName}}."
}

module.exports = translations
