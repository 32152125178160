import React, { useRef, useState } from 'react'
import { Box, Form, FormField, TextInput, Button, Heading } from 'grommet'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { useLocation, useNavigate } from 'react-router-dom'
import ErrorProvider from './ErrorProvider'
import ReCAPTCHA from 'react-google-recaptcha'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAuth, signInWithCustomToken } from 'firebase/auth'

const Login = () => {
  const [value, setValue] = useState({ email: '', password: '' })

  const location = useLocation()
  const navigate = useNavigate()

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const captchaRef = useRef(null)

  const onLogin = async ({ value }) => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('loggingIn'),
      closeable: false
    })

    try {
      const token = captchaRef.current.getValue()
      captchaRef.current.reset()

      const functions = getFunctions()
      const verifyRecaptcha = httpsCallable(functions, 'verifyRecaptcha')
      const result = await verifyRecaptcha({ token })
      const verificationResponse = result.data

      if (!verificationResponse.success) {
        addError(
          I18n.t('recaptchaFailed', {
            error: verificationResponse['error-codes'].join(', ')
          })
        )
        GlobalConfig.hideProgress()
        return
      }

      const { email, password } = value

      // Get authToken from the server
      const loginAdminAppUser = httpsCallable(functions, 'loginAdminAppUser')
      const authResult = await loginAdminAppUser({
        email,
        password
      })
      const authToken = authResult.data.authToken

      const auth = getAuth()

      // Sign in with custom token
      await signInWithCustomToken(auth, authToken)

      const from = location.state?.from?.pathname || '/'
      navigate(from, { replace: true })
      GlobalConfig.hideProgress()
    } catch (error) {
      console.error('onLogin', error)

      switch (error.code) {
        case 'auth/user-not-found':
          addError(I18n.t('userNotFound'))
          break
        case 'auth/wrong-password':
          addError(I18n.t('wrongPassword'))
          break
        default:
          addError(error.message)
          break
      }

      GlobalConfig.hideProgress()
    }
  }

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box align="center">
        <Heading level={4} margin={{ left: 'medium' }}>
          {I18n.t('login')}
        </Heading>
        <Box
          background="white"
          pad="small"
          round="small"
          margin="medium"
          width="large">
          <Form
            value={value}
            onChange={nextValue => setValue(nextValue)}
            validate="submit"
            onSubmit={onLogin}>
            <FormField htmlFor="email" name="email" label={I18n.t('email')}>
              <TextInput id="email" name="email" />
            </FormField>
            <Box direction="row">
              <FormField
                flex
                htmlFor="password"
                name="password"
                label={I18n.t('password')}>
                <TextInput type="password" id="password" name="password" />
              </FormField>
            </Box>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />
            </Box>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <Button
                type="submit"
                primary
                style={{ color: 'white' }}
                label={I18n.t('login')}
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </ErrorProvider>
  )
}

export default Login
