import React, { useState } from 'react'
import { Box, Form, FormField, TextInput, Button, Heading } from 'grommet'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { useNavigate } from 'react-router-dom'
import ErrorProvider from './ErrorProvider'
import { collection, doc, setDoc } from 'firebase/firestore'

const NewAffiliate = () => {
  const [value, setValue] = useState({ email: '', password: '' })

  const navigate = useNavigate()

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const onCreateAffiliate = async ({ value }) => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('creatingAffiliate'),
      closeable: false
    })

    try {
      const { name, email } = value

      // Create affiliate on Firestore Database
      const affiliateRef = doc(
        collection(GlobalConfig.firestoreDb, 'affiliates')
      )
      const affiliateId = affiliateRef.id

      await setDoc(affiliateRef, {
        id: affiliateId,
        name,
        email,
        referralCount: 0
      })

      navigate('/app/affiliates', { replace: true })
      GlobalConfig.hideProgress()
    } catch (error) {
      console.error('onCreateAffiliate', error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box align="center">
        <Heading level={4} margin={{ left: 'medium' }}>
          {I18n.t('addAffiliate')}
        </Heading>
        <Box
          background="white"
          pad="small"
          round="small"
          margin="medium"
          width="large">
          <Form
            value={value}
            onChange={nextValue => setValue(nextValue)}
            validate="submit"
            onSubmit={onCreateAffiliate}>
            <FormField htmlFor="name" name="name" label={I18n.t('name')}>
              <TextInput id="name" name="name" />
            </FormField>
            <FormField htmlFor="email" name="email" label={I18n.t('email')}>
              <TextInput id="email" name="email" />
            </FormField>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <Button
                type="submit"
                primary
                style={{ color: 'white' }}
                label={I18n.t('add')}
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </ErrorProvider>
  )
}

export default NewAffiliate
