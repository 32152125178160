import React from 'react'
import { Outlet } from 'react-router-dom'
import Breadcrumbs from './Breadcrumbs'
import { Box } from 'grommet'

const AppLayout = () => {
  return (
    <Box align="center" justify="center">
      <Box width="large" pad="small" align="start">
        <Breadcrumbs />
      </Box>
      <Outlet />
    </Box>
  )
}

export default AppLayout
