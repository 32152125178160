import './App.css'
import React, { useEffect, useState } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  Link,
  Outlet
} from 'react-router-dom'
import Layout from './components/Layout'
import RequireAuth from './components/RequireAuth'
import Login from './components/Login'
import UserContext from './components/UserContext'
import NoMatch from './components/NoMatch'
import AppLayout from './components/AppLayout'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import HomePage from './components/HomePage'
import { Constants, I18n } from 'galarm-config'
import { WebUtils } from 'galarm-shared'
import { Grommet } from 'grommet'
import Organizations from './components/Organizations'
import Organization, {
  loader as organizationLoader
} from './components/Organization'
import Affiliates from './components/Affiliates'
import Affiliate, { loader as affiliateLoader } from './components/Affiliate'
import NewAffiliate from './components/NewAffiliate'

function App() {
  const [user, setUser] = useState(null)
  const [userLoaded, setUserLoaded] = useState(false)

  const colorScheme = Constants.COLOR_SCHEMES.LIGHT
  const theme = WebUtils.getGrommetTheme(colorScheme)

  useEffect(() => {
    const auth = getAuth()
    const unsubscribeFromAuthChanges = onAuthStateChanged(auth, async user => {
      if (!user) {
        setUser(null)
        setUserLoaded(true)
      } else {
        console.log('logged in user', user.uid)
        setUser(user)
        setUserLoaded(true)
      }
    })
    return () => {
      unsubscribeFromAuthChanges()
    }
  }, [])

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: 'login',
          element: <Login />
        },
        {
          path: 'app',
          element: (
            <RequireAuth>
              <AppLayout />
            </RequireAuth>
          ),
          handle: {
            crumb: () => <Link to="/app">{I18n.t('home')}</Link>
          },
          children: [
            {
              path: '',
              element: <HomePage />
            },
            {
              path: 'orgs',
              element: <Outlet />,
              handle: {
                crumb: () => (
                  <Link to="/app/orgs">{I18n.t('organizations')}</Link>
                )
              },
              children: [
                {
                  path: '',
                  element: <Organizations />
                },
                {
                  path: ':orgId',
                  element: <Organization />,
                  handle: {
                    crumb: enterpriseAccount => {
                      return (
                        <Link to={'/app/orgs/' + enterpriseAccount.id}>
                          {enterpriseAccount.name}
                        </Link>
                      )
                    }
                  },
                  loader: organizationLoader
                }
              ]
            },
            {
              path: 'affiliates',
              element: <Outlet />,
              handle: {
                crumb: () => (
                  <Link to="/app/affiliates">{I18n.t('affiliates')}</Link>
                )
              },
              children: [
                {
                  path: '',
                  element: <Affiliates />
                },
                {
                  path: 'new',
                  element: <NewAffiliate />
                },
                {
                  path: ':affiliateId',
                  element: <Affiliate />,
                  handle: {
                    crumb: affiliate => {
                      return (
                        <Link to={'/app/affiliates/' + affiliate.id}>
                          {affiliate.name}
                        </Link>
                      )
                    }
                  },
                  loader: affiliateLoader
                }
              ]
            }
          ]
        },
        {
          path: '*',
          element: <NoMatch />
        }
      ]
    }
  ])

  return (
    <Grommet full theme={theme}>
      <UserContext.Provider value={{ user, userLoaded }}>
        <RouterProvider router={router} />
      </UserContext.Provider>
    </Grommet>
  )
}

export default App
