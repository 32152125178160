import React from 'react'
import { Box, Button, TextInput, Text } from 'grommet'
import { I18n } from 'galarm-config'
import { Close } from 'grommet-icons'

const SearchBar = props => {
  const resetSearchBox = () => {
    if (props.searchText) {
      props.onChangeText('')
    }
  }

  const onChangeText = newText => {
    props.onChangeText(newText)
  }

  return (
    <Box
      direction="row"
      flex={{ shrink: 0, grow: 1 }}
      pad={{ horizontal: 'small' }}>
      <Box flex round="small" direction="row" align="center" background="white">
        <TextInput
          autoFocus={props.focus}
          plain
          value={props.searchText}
          placeholder={I18n.t('search')}
          onChange={event => onChangeText(event.target.value)}
          color="textColor"
        />
        {!!props.searchText && (
          <Button icon={<Close />} onClick={() => resetSearchBox()} />
        )}
      </Box>
      {props.onCloseSearchBar && (
        <Box
          onClick={props.onCloseSearchBar}
          pad={{ left: 'small' }}
          justify="center">
          <Text color={'primary'}>{I18n.t('cancel')}</Text>
        </Box>
      )}
    </Box>
  )
}
export default SearchBar
