import { Box, Text } from 'grommet'
import React from 'react'
import { Close } from 'grommet-icons'

const ErrorProvider = ({ errors, getErrorMessage, onClearError, children }) => {
  return (
    <Box overflow="auto">
      <Box
        flex={{ shrink: 0 }}
        align="center"
        margin={errors.length ? 'medium' : 'none'}
        gap="small">
        {errors.map((error, index) => {
          return (
            <Box
              key={index}
              direction="row"
              gap="medium"
              round="small"
              pad={{ vertical: 'small', left: 'small', right: 'medium' }}
              background="warningTileBackgroundColor">
              <Text>{getErrorMessage(error)}</Text>
              <Box onClick={() => onClearError(error)}>
                <Close />
              </Box>
            </Box>
          )
        })}
      </Box>
      {children}
    </Box>
  )
}

export default ErrorProvider
