import React from 'react'
import { useMatches } from 'react-router-dom'
import { Box } from 'grommet'
import { FormNext } from 'grommet-icons'

const Breadcrumbs = () => {
  let matches = useMatches()
  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter(match => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map(match => match.handle.crumb(match.data))

  return (
    <Box direction="row" align="center">
      {crumbs.map((crumb, index) => (
        <Box key={index} direction="row" align="center">
          {crumb}
          {index < crumbs.length - 1 && <FormNext />}
        </Box>
      ))}
    </Box>
  )
}

export default Breadcrumbs
