import { GlobalConfig } from 'galarm-config'
import { onSnapshot, collection, doc } from 'firebase/firestore'

const FirebaseManager = (function () {
  let unsubs = {}

  const listenToAlarmsForUser = function (key, userId, callback) {
    const unsub = onSnapshot(
      query(
        collection(GlobalConfig.firestoreDb, 'userInfos', userId, 'alarms')
      ),
      snapshot => {
        callback(snapshot)
      }
    )

    // Add unsub to array with key in unsubs object
    if (unsubs[key]) {
      unsubs[key].push(unsub)
    } else {
      unsubs[key] = [unsub]
    }
  }

  const listenToAlarm = function (key, alarmId, callback) {
    const unsub = onSnapshot(
      doc(GlobalConfig.firestoreDb, 'alarms', alarmId),
      snapshot => {
        callback(snapshot)
      }
    )

    // Add unsub to array with key in unsubs object
    if (unsubs[key]) {
      unsubs[key].push(unsub)
    } else {
      unsubs[key] = [unsub]
    }
  }

  const listenToAlarmAcknowledgements = function (key, alarmId, callback) {
    const unsub = onSnapshot(
      query(
        collection(
          GlobalConfig.firestoreDb,
          'alarms',
          alarmId,
          'acknowledgements'
        )
      ),
      snapshot => {
        callback(snapshot)
      }
    )

    // Add unsub to array with key in unsubs object
    if (unsubs[key]) {
      unsubs[key].push(unsub)
    } else {
      unsubs[key] = [unsub]
    }
  }

  const listenToAlarmStatusChanges = function (key, alarmId, callback) {
    const unsub = onSnapshot(
      query(
        collection(GlobalConfig.firestoreDb, 'alarms', alarmId, 'statusChanges')
      ),
      snapshot => {
        callback(snapshot)
      }
    )

    // Add unsub to array with key in unsubs object
    if (unsubs[key]) {
      unsubs[key].push(unsub)
    } else {
      unsubs[key] = [unsub]
    }
  }

  const listenToUnseenMessagesCountForAlarmForUser = function (
    key,
    alarmId,
    uid,
    callback
  ) {
    const unsub = onSnapshot(
      doc(
        GlobalConfig.firestoreDb,
        'alarms',
        alarmId,
        'unseenMessagesCount',
        uid
      ),
      snapshot => {
        callback(snapshot)
      }
    )

    // Add unsub to array with key in unsubs object
    if (unsubs[key]) {
      unsubs[key].push(unsub)
    } else {
      unsubs[key] = [unsub]
    }
  }

  const unsubscribeAllListeners = function () {
    for (const key in unsubs) {
      unsubs[key].forEach(unsub => {
        unsub()
      })
    }
  }

  const unsubscribeListenersForKey = function (key) {
    if (unsubs[key]) {
      unsubs[key].forEach(unsub => {
        unsub()
      })
    }
  }

  return {
    listenToAlarmsForUser,
    listenToAlarm,
    unsubscribeAllListeners,
    unsubscribeListenersForKey,
    listenToAlarmAcknowledgements,
    listenToAlarmStatusChanges,
    listenToUnseenMessagesCountForAlarmForUser
  }
})()

export default FirebaseManager
