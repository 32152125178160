import React from 'react'
import { Box, Heading, Image, ResponsiveContext, Text } from 'grommet'
import { I18n } from 'galarm-config'

const logoHeights = {
  small: '30px',
  medium: '40px',
  large: '50px',
  xlarge: '60px'
}

const companyNameLevels = {
  small: 6,
  medium: 5,
  large: 4,
  xlarge: 3
}

const ResponsiveCompanyLogo = () => (
  <ResponsiveContext.Consumer>
    {size => {
      return (
        <Box>
          <Image
            src={require('galarm-res/img/web/galarm-logo.svg').default}
            height={logoHeights[size]}
          />
          {size !== 'small' && size !== 'small' && (
            <Box>
              <Heading
                color="primary"
                margin="none"
                level={companyNameLevels[size]}>
                {I18n.t('galarmAdmin')}
                <Text
                  size="xsmall"
                  color="darkTint"
                  margin="3px"
                  alignSelf="end">
                  {'beta'}
                </Text>
              </Heading>
            </Box>
          )}
        </Box>
      )
    }}
  </ResponsiveContext.Consumer>
)

export default ResponsiveCompanyLogo
