import invariant from 'invariant'
import { GlobalConfig } from 'galarm-config'
import uuid from 'react-native-uuid'
import { FirebaseProxy, LogUtils } from 'galarm-ps-api'
import CryptoJS from 'react-native-crypto-js'
import Config from 'react-native-config'
import { Platform } from 'react-native'

const TaskManager = (function () {
  const ref = GlobalConfig.rootFirebaseRef

  const addTask = function (task, taskResultHandler) {
    const taskId = uuid.v4()

    invariant(
      Object.prototype.hasOwnProperty.call(task, '_state'),
      "Task doesn't contain the required '_state' property"
    )

    task['taskId'] = taskId
    task['fromUid'] = GlobalConfig.uid || null
    task['ip'] = GlobalConfig.ip || null
    task['deviceUid'] = GlobalConfig.deviceUid || null

    const startTime = Date.now()

    ref
      .child('queue/tasks')
      .push(task)
      .then(() => {
        // Increment the counter
        ref.child('queue/numTasks').transaction(numTasks => {
          if (!numTasks) {
            return 1
          } else {
            return numTasks + 1
          }
        })

        const taskAddedTime = Date.now()

        // If user has passed a valid taskResultHandler, then listen on the
        // task results and invoke the taskResultHandler
        if (!!taskResultHandler && typeof taskResultHandler === 'function') {
          const taskResultRef = ref.child('taskResults/' + taskId)
          taskResultRef.on('value', snapshot => {
            if (snapshot.exists()) {
              const taskResult = snapshot.val()

              invariant(
                Object.prototype.hasOwnProperty.call(taskResult, 'status'),
                "Task result doesn't contain status"
              )

              if (taskResult.status === 'failure') {
                taskResultHandler(taskResult.error)
              } else {
                const taskResultReceivedTime = Date.now()
                taskResultHandler(null, taskResult.result)
                __DEV__ &&
                  LogUtils.logMessage(
                    'Task Profiling Statistics ' +
                      JSON.stringify({
                        taskName: task._state,
                        taskId: taskId,
                        uid: GlobalConfig.uid,
                        taskStartTime: startTime,
                        taskAddedTime,
                        taskResultReceivedTime,
                        timeTakenToAddTask: taskAddedTime - startTime,
                        timeTakenToReceiveResult:
                          taskResultReceivedTime - taskAddedTime,
                        totalTime: taskResultReceivedTime - startTime
                      })
                  )
              }

              taskResultRef.remove().catch(function (error) {
                console.tron.log(
                  'Unable to delete task results for task ' +
                    task +
                    '\n' +
                    error
                )
              })

              taskResultRef.off('value')
            }
          })
        }
      })
      .catch(error => {
        LogUtils.logError(error, 'Unable to add task', { task: task })

        if (!!taskResultHandler && typeof taskResultHandler === 'function') {
          taskResultHandler('Unable to add task. Error: ', error.message)
        }
      })
  }

  const addCloudTask = function (location, task, expectResults = true) {
    const promise = new Promise((resolve, reject) => {
      task['appSecret'] = GlobalConfig.appSecret
      task['fromUid'] = GlobalConfig.uid || null
      task['ip'] = GlobalConfig.ip || null
      task['deviceUid'] = GlobalConfig.deviceUid || null

      let taskId
      try {
        const taskRef = ref.child('tasks/' + location).push(task)
        taskId = taskRef.key
        taskRef.catch(error => {
          LogUtils.logError(error, 'Unable to add cloud task', {
            location,
            task
          })
        })
      } catch (error) {
        LogUtils.logError(error, 'Unable to add cloud task', { location, task })
        reject(error)
        return
      }

      if (!expectResults) {
        resolve(true)
        return
      }

      const taskResultRef = ref.child('taskResults/' + taskId)
      taskResultRef.on('value', snapshot => {
        if (snapshot.exists()) {
          const taskResult = snapshot.val()
          if (taskResult.status === 'failure') {
            reject(taskResult)
          } else {
            resolve(taskResult)
          }
          taskResultRef.remove().catch(function (error) {
            console.tron.log(
              'Unable to delete task results for task ' + taskId + '\n' + error
            )
          })

          taskResultRef.off('value')
        }
      })
    })
    return promise
  }

  const addHttpsCloudTask = function (location, task) {
    var cloudTask = FirebaseProxy.functions().httpsCallable(location + 'Https')

    // Append the source to all tasks
    task['fromUid'] = GlobalConfig.uid || null
    task['ip'] = GlobalConfig.ip || null

    const aesKey = Platform.select({
      ios: Config.aesKey,
      android: Config.aesKey,
      web: process.env.REACT_APP_AES_KEY
    })

    const hash = CryptoJS.MD5(GlobalConfig.deviceUid + aesKey).toString()
    const expiry = Date.now() + 300000 // token expires in 5 minutes

    const accessToken = {
      deviceUid: GlobalConfig.deviceUid,
      hash: hash,
      expiry: expiry
    }

    let cipher = CryptoJS.AES.encrypt(
      JSON.stringify(accessToken),
      aesKey
    ).toString()

    task['cipher'] = cipher

    console.tron.log(
      `Adding cloud task ${location}.`,
      GlobalConfig.uid,
      GlobalConfig.ip,
      GlobalConfig.deviceUid
    )

    const promise = new Promise((resolve, reject) => {
      cloudTask(task)
        .then(result => {
          console.tron.log(`Cloud task ${location} result:`, result)

          if (result.data?.status === 'failure') {
            // No need to log an error if the task fails
            // LogUtils.logError(
            //   new Error(result.data.error),
            //   'Unable to add https cloud task',
            //   { location }
            // )

            reject(result.data)
            return
          }

          resolve(result.data)
        })
        .catch(error => {
          console.log('Exception while adding https cloud task', error)
          reject(error)
        })
    })
    return promise
  }

  const invokeGalarmApi = function (path, body = {}) {
    const promise = new Promise((resolve, reject) => {
      const url = path.startsWith('/')
        ? GlobalConfig.galarmApiUrl + path
        : GlobalConfig.galarmApiUrl + '/' + path

      console.tron.log('Invoking Galarm API', url, body)

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + GlobalConfig.idToken
        },
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(responseJson => {
          console.tron.log('Galarm API response', responseJson)
          resolve(responseJson)
        })
        .catch(error => {
          console.tron.log('Exception while invoking Galarm API', error)
          reject(error)
        })
    })
    return promise
  }

  return {
    addTask,
    addCloudTask,
    addHttpsCloudTask,
    invokeGalarmApi
  }
})()

export default TaskManager
