import { Box, Spinner, Text } from 'grommet'
import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import UserContext from './UserContext'
import { I18n } from 'galarm-config'

const RequireAuth = ({ children }) => {
  const { user, userLoaded } = useContext(UserContext)

  const location = useLocation()

  if (!userLoaded) {
    return (
      <Box flex justify="center" align="center">
        <Spinner size="medium" />
        <Text>{I18n.t('loadingUser')}</Text>
      </Box>
    )
  }

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location.pathname }} />
  }

  return children
}

export default RequireAuth
