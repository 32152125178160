import { doc, getDoc } from 'firebase/firestore'
import { I18n } from 'galarm-config'
import { Card, CardBody, Heading, Box, Text } from 'grommet'
import React from 'react'
import { useLoaderData } from 'react-router-dom'
import GlobalConfig from '../GlobalConfig'

export const loader = async ({ params }) => {
  console.log('Affiliate loader params', params)
  const affiliateId = params.affiliateId

  return getDoc(doc(GlobalConfig.firestoreDb, 'affiliates', affiliateId))
    .then(doc => {
      if (doc.exists()) {
        return doc.data()
      } else {
        throw new Error('Affiliate does not exist')
      }
    })
    .catch(error => {
      throw new Error('Error fetching affiliate. ' + error.message)
    })
}

const Affiliate = () => {
  const affiliate = useLoaderData()

  console.log('affiliate', affiliate)
  return (
    <Box margin="medium">
      <Card width="large" background="textBackgroundColor">
        <CardBody pad="medium" overflow="auto" gap="xsmall">
          <Heading level={4} margin="none">
            {affiliate.name}
          </Heading>
          <Box>
            <Text size="small" weight={'bold'}>
              {I18n.t('id') + ':'}
            </Text>
            <Text size="small">{affiliate.id}</Text>
          </Box>
        </CardBody>
      </Card>
    </Box>
  )
}

export default Affiliate
